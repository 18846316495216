.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  border-radius: 10px;
  background: rgba(229, 233, 240, 0.43);
  padding: 55px;
  position: relative;
}

.suptitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.title {
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.btn {
  width: 100%;
}

.expired {
  opacity: 0.6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 999;
}
