.wrapper{
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  border-radius: 45px;
  padding: 13vh 5.21vw 9.26vw 7vw;
  gap: 65px;
  color: white;
  height: 39.81vh;
  width: 48%;
}
.percent{
  font-size: 2.81vw;
  line-height: 2.81vw;
  font-weight: 700;
  font-family: Neue Machina;
}
.description{
  font-size: 1.15vw;
  line-height: 1.35vw;
  font-weight: 300;
  font-family: Manrope;
}
@media screen and (max-width: 1391.30px){
  .description{
    font-size: 16px;
    line-height: 19px;
  }
}
@media screen and (max-width: 1138.79px){
  .percent{
    font-size: 32px;
    line-height: 32px;
  }
}
@media screen and (max-width: 1200px) {
  .wrapper{
    padding: 30px;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 20px;
    border-radius: 15px;
  }
}