.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  margin-bottom: 55px;
}
.content{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card{
  display: flex;
  flex-direction: column;
  padding: 55px;
  border-radius: 15px;
  background-color: var(--accent);
  gap: 15px;
}
.card div{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.card p{
  color: #FFF;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
  letter-spacing: -0.8px;
  margin: 0;
}

.text{
  color: #8F939C;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.96px;
  margin: 0;
  margin-top: 25px;
}

.button{
  background-color: black;
  margin-top: 60px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .title{
    font-size: 36px;
    margin-bottom: 40px;
  }
  .card{
    padding: 35px;
  }
  .text{
    font-size: 21px;
  }
  .card div{
    gap: 15px;
  }
  .card p{
    font-size: 17px;
  }
}

@media screen and (max-width: 600px) {
  .title{
    font-size: 24px;
    margin-bottom: 20px;
  }
  .card{
    padding: 20px;
  }
  .text{
    font-size: 18px;
  }
  .card div{
    gap: 10px;
  }
  .card p{
    font-size: 14px;
  }
}