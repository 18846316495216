.menuNav {}

.menuList {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0;
}

.horizontalMenu {
  flex-direction: row;
  padding-bottom: 25px;
}
