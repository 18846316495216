.wrapper{
  display: flex;
  flex-direction: column;
  gap: 47px;
}
.titleWrapper{
  display: flex;
  flex-direction: row;
  gap: 9.38vw;;
}
.title{
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 48px */
  letter-spacing: -1.92px;
  margin: 0;
}
.titleButton{
  color: black;
  flex-direction: row-reverse
}
.iconButton{
  rotate: 180deg;
  transform: translate(0, 2px);
}

.content{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.QRtitle{
  color: #8F939C;
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  letter-spacing: -0.96px;
  margin: 0;
  margin-bottom: 60px;
}
.FAcode{
  color: var(--dark, #001007);
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  letter-spacing: -0.96px;
  margin: 0;
  margin-top: 60px;
}
.input{
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 580px;
}
.name{
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  letter-spacing: -0.32px;
}
.inputButton{
  margin-top: 20px;
  width: 100%;
}

.codeInput::-webkit-outer-spin-button,
.codeInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.codeInput {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1300px) {
  .titleWrapper{
    flex-direction: column;
    gap: 50px;
  }
  .title{
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper{
    gap: 35px;
  }
  .title{
    font-size: 36px;
  }
  .input{
    width: 100%;
  }
  .QRtitle, .FAcode{
    font-size: 20px;
  }
}
@media screen and (max-width: 600px) {
  .wrapper{
    gap: 20px;
  }
  .title{
    font-size: 24px;
  }
  .QRtitle, .FAcode{
    font-size: 16px;
  }
}
