.wrapper{
  display: flex;
  flex-direction: column;
  gap: 65px;
}
.subWrapper{
  display: flex;
  flex-direction: column;
  padding: 35px 55px 55px 55px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
}
.top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text > h2{
  margin: 0;
}
.text > p{
  color: #8F939C;
  margin: 0;
  margin-bottom: 5px;
}
.top > .button{
  padding: 20px 25px;
  background-color: #87C7AB;
}
.bottom{
  display: flex;
  flex-direction: column;
}
.input{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
}
.input button{
  width: 315px;
}
.input div{
  flex: 1 0;
  gap: 0;
}
.coinIcon{
  filter: invert(61%) sepia(13%) saturate(191%) hue-rotate(183deg) brightness(94%) contrast(88%);
}
.cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.card{
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 35px 55px 55px 55px;
  gap: 25px;
  background-color: white;
  border: 1px solid var(--grey-3, #EBEFF4);
  border-radius: 15px;
}
.card > .title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card > .title > p{
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
}
.card > .title > div{
  padding: 20px;
  background-color: #F4F5F7;
  border-radius: 10px;
}
.card .name{
  color: #8F939C;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  margin-bottom: 5px;
}
.card .value{
  margin: 0;
  font-size: 26px;
  line-height: 26px;
}
.second > div{
  display: flex;
  flex-direction: row;
}
.coin{
  height: 30px;
}

@media screen and (max-width: 1200px) {
  .subWrapper{
    gap: 35px;
  }
  .top{
    flex-direction: column;
  }
  .top > .button{
    display: none;
  }
  .input button, .input div{
    width: 100%;
  }
  .input{
    flex-direction: column;
    gap: 25px;
  }
  .cards{
    gap: 10px;
  }
}


.bold {
  font-weight: 700;
}

.tip {
  position: relative;
  color: var(--dark);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  padding: 10px 30px;
  border: 1px solid black;
  width: 100%;
  border-radius: 10px;
  opacity: 1;
  transition: 0.3s ease-in-out;
  background: #fff;
  margin-top: 25px;
}