.drawerWrapper, .drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1999;
  transition: 0.5s ease-in-out;
}

.drawerWrapper {
  z-index: 999;
  overflow: hidden;
}

.drawer {
  border-radius: 15px 0px 0px 15px;
  background: #EBEBEB;
  backdrop-filter: blur(10px);
  left: 21px;
}

.head {
  padding: 22px 18px 0 0;
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  padding: 2.5px;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;
  padding-left: 45px;
}

.item a {
  color: #001007;
  font-family: Neue Machina;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
  text-transform: uppercase;
}

.actions {
  position: absolute;
  right: 20px;
  left: 45px;
  bottom: 26px;
}

.actions button {
  width: 100%;
}

.signin {
  border-radius: 5px;
  margin-bottom: 5px;
}

.signup {
  border-radius: 5px;
  border: 1px solid var(--grey-1, #8F939C) !important;
  color: var(--dark) !important;
}

.langSelector {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--grey-4, #F4F5F7);
  width: fit-content;
  margin: 0 0 0 45px;
}
