.title > p,
.subtitle > p,
.addition > p{
  margin: 0;
}
.wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  position: relative;
}
.title{
  width: 56%;
  font-size: 4.48vw;  
  line-height: 4.48vw;
  font-family: Neue Machina;
}
.right{
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-top: auto;
  margin-right: auto;
  margin-left: 220px;
  margin-bottom: -10px;
  gap: 20px;
}
.subtitle{
  font-size: 1.25vw;  
  line-height: 1.51vw;
  font-weight: 600;
  margin: 0;
  width: 70%;
}
.addition{
  font-size: 1.25vw;  
  line-height: 1.51vw;
  color: #8F939C;
}
.line{
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.06);
}
.right > .line{
  margin: 50px 0 15px 0;
}

@media screen and (max-width: 1200px){
  .wrapper{
    flex-direction: column;
  }
  .title, .right{
    width: 100%;
  }
  .right{
    margin-top: 25px;
    margin-left: 0;
  }
  .addition{
    margin-top: 20px;
  }
  .right > .line{
    display: none;
  }
}
@media screen and (max-width: 800px){
  .addition{
    margin: 25px 0 0 0;
  }
}

@media screen and (max-width: 535.71px){
  .title{
    font-size: 24px;
    line-height: 24px;  
  }
}
@media screen and (max-width: 1440.00px){
  .subtitle{
    font-size: 18px;
    line-height: 22px;  
  }
}
@media screen and (max-width: 1280.00px){
  .addition{
    font-size: 16px;
    line-height: 19px;  
  }
}
