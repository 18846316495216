.wrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.button{
  padding: 0;
  border: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: transparent;
}
.icon{
  margin: 0;
  padding: 0;
}
.wrapper .button:first-child .icon{
  rotate: 180deg;
}
.wrapper .button:last-child .icon{
  rotate: 0deg;
}
.wrapper .button:first-child{
  margin-right: 15px;
}
.wrapper .button:last-child{
  margin-left: 15px;
}

.numbers{
  display: flex;
  flex-direction: row;
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 5px;
}

.num {
  cursor: pointer;
}
