.wrapper {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  width: 4.17vw;
  height: 4.17vw;
  border-radius: 50%;
  background-color: var(--accent);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1200px) {
  .wrapper {
    left: 250px;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    width: 45px;
    height: 45px;
  }
  .icon {
    width: 19.687px;
    height: 19.687px;
  }
}
