.i {
  position: relative;
  font-weight: 500;
  width: 0;
  text-align: center;
}
.frameParent {
  position: absolute;
  bottom: 0;
  /* backdrop-filter: var(--blur); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3.85vw;
  padding: 0 3.13vw;
  width: 100%;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-gray-600);
  font-family: var(--font-neue-machina);
}
.frameGroup,
.iParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.whiteLine {
  position: absolute;
  height: 2px;
  bottom: 100%;
  background: #fff;
  transition: 0.25s;
}
.iParent {
  font-size: 1.25vw;
  line-height: 1.21vw;
  gap: 3.91vw;
}
.iParent .i {
  transition: 0.25s;
}

.iParent .i.bolder {
  color: #fff;
}

.frameGroup {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  /* top: 25px; */
  left: 0;
  padding: 1.3vw 0;
  justify-content: space-between;
  width: 100%;
}

.bgDark {
  background: var(--dark) !important;
}

.dark {
  color: var(--dark);
}

.accentLetter {
  color: var(--accent) !important;
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 909.09px) {
  .frameParent {
    height: 35px;
  }
}
@media screen and (max-width: 769.23px) {
  .frameGroup {
    padding: 10px 0;
  }
}
@media screen and (max-width: 1120px) {
  .iParent {
    font-size: 14px;
    line-height: 13.51px;
  }
}
