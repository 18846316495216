.wrapper{
  display: flex;
  flex-direction: column;
  width: 33%;
  position: relative; 
  gap: 20px;
  z-index: 0;
}
.img{
  height: 100%;
  z-index: 2;
}
.textWrapper{
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
}

.textWrapper button {
  height: 51.52px;
}

.specialist{
  color: #8F939C;
  font-size: 16px;
  line-height: 16px;
  font-family: Manrope;
  font-weight: 500;
  margin: 0;
}
.name{
  font-size: 1.98vw;  
  line-height: 1.98vw;;
  font-family: Neue Machina;
  font-weight: 700;
  margin: 0;
}

.imgPlaceholder {
  background: var(--accent);
  width: 100%;
  max-height: 630px !important;
  height: 100% !important;
}

.placeholderText {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 36px;
  right: 36px;
  transform: translate(0, -100%);
  color: white;
  font-size: 35px;
  text-align: center;
}

@media screen and (max-width: 1212.12px){
  .name{
    font-size: 24px;
    line-height: 24px;  
  }
}

@media screen and (max-width: 800px) {
  .wrapper{
    width: 100%;
  }
}
