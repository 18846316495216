.langSelector {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-left: 26px;
}

.light {
  color: white;
}

.dark {
  color: var(--dark);
}

.flag {
  margin-right: 5px;
}

.dropdownWrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 61px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 15px 15px;
  padding: 21.5px 41px 25px 36.6px;
  gap: 16.68px;
  z-index: 999;
}
.dropdownWrapper.dark{
  background-color: white;
}
.dropdownWrapper.light{
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
}
.dropdownBlock{
  position: absolute;
  height: 110px;
  width: 125px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.dropdownLang{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6.67px;
  user-select: none;
}
.langName{
  margin: 0;
}
.langImg{
  width: 16.67px;
  height: 15.42px;
}
