.title > p,
.subtitle > p,
.addition > p{
  margin: 0;
}
.wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  margin-bottom: 200px;
  position: relative;
}
.title{
  width: 60%;
  font-size: 4.479166666666667vw;
  line-height: 4.479166666666667vw;
  font-weight: 500;
  font-family: Neue Machina;
}
.overtitle{
  padding-bottom: 20px;
  margin-bottom: auto;
  font-size: 1.875vw;
  line-height: 1.8093750000000002vw;
  letter-spacing: -1px;
  font-weight: 600;
  font-family: Neue Machina;
}

.subtitle{
  font-size: 1.25vw;
  line-height: 1.6vw;
  font-family: Manrope;
  margin: 0;
  margin-top: 120px;
  letter-spacing: 0.3px;
  margin-bottom: 35px;
}
.addition{
  font-size: 1.25vw;
  line-height: 1.6vw;
  font-family: Manrope;
  color: #8F939C;
}
.right{
  display: flex;
  flex-direction: column;
  width: 31%;
  margin-top: -20px;
  margin-left: 135px
}
.line{
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.06);
}
.right > .line{
  margin: 50px 0 15px 0;
}
@media screen and (max-width: 536px) {
  .title{
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 854px){
  .overtitle{
    font-size: 16px;
    line-height: 16px;
  }
}
@media screen and (max-width: 1440px){
  .subtitle{
    font-size: 18px;
    line-height: 21px;
  }
}
@media screen and (max-width: 1280px){
  .addition{
    font-size: 16px;
    line-height: 19px;
  }
}

@media screen and (max-width: 1200px){
  .wrapper{
    flex-direction: column;
  }
  .title, .right{
    width: 100%;
  }
  .right{
    margin-top: 25px;
    margin-left: 0;
  }
  .addition{
    margin-top: 20px;
  }
  .right > .line{
    display: none;
  }
}
@media screen and (max-width: 800px){
  .subtitle{
    margin: 0;
  }
  .addition{
    margin: 25px 0 0 0;
  }
  .wrapper{
    margin: 40px 0;
  }
}