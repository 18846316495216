.container {
  display: flex;
  background: #E5E9F0;
  padding: 35px;
  gap: 20px;
}

.content {
  /*flex: 1 0 auto;*/
  width: 100%;/**/
  background: #F9F9F9;
  border-radius: 30px;
}

.sidebar {
  display: block;
}

.wrapper {
  padding: 0 51px 55px 51px;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

h1 {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.92px;
  margin-bottom: 25px;
}

h2 {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.28px;
  margin-bottom: 25px;
}

@media screen and (max-width: 805px) {
  .container {
    padding: 0;
  }

  .sidebar {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .wrapper {
    padding: 0 20px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 805px) {
  .content {
    border-radius: 0;
  }

  .wrapper {
    padding-bottom: 100px;
    gap: 40px;
  }

  #launcher-frame {
    bottom: 75px !important;
  }
}


.help{
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  float: right;
  border-radius: 30px 8px 30px 30px;
  background-color: var(--accent);
  box-shadow: none;
  color: rgb(255, 255, 255);
  z-index: 2147483647;
  right: 30px;
  bottom: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  cursor: pointer;
  padding: 0 12px 0 0;
  transition: all 0.1s ease-out 0s;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.help:hover{
  scale: 1.1;
  box-shadow: 0 2px 3px gray;
}
.circle{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 100%;
  font-size: 14px;
  width: 22px;
  height: 22px;
  margin: 8px;
}
