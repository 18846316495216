.card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 555px;
  padding: 0 77.5px;
  gap: 45px;
  background: rgba(255, 255, 255, 0.31);
  backdrop-filter: blur(15px);
  border-radius: 15px;
}
.number{
  font-size: 3.02vw;  
  line-height: 3.02vw;
  font-family: Neue Machina;
  font-weight: 700;
}
.text{
  font-size: 1.15vw;  
  line-height: 1.51vw;
}
@media screen and (max-width: 1200px) {
  .card{
    height: 102px;
    width: 100%;
    padding: 0 30px;
    gap: 25px;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 1059.60px){
  .number{
    font-size: 32px;
    line-height: 32px;  
  }
}
@media screen and (max-width: 1391.30px){
  .text{
    font-size: 16px;
    line-height: 21px;  
  }
}
