.cards{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
}

@media screen and (max-width: 800px) {
  .cards{
    flex-direction: column;
  }
}