.storeProducts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media screen and (max-width: 1150px) {
  .storeProducts {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
@media screen and (max-width: 800px) {
  .storeProducts {
    padding-bottom: 100px;
  }
}
