.title{
  text-align: center;
  font-family: Manrope;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%;
  letter-spacing: -1.44px;
}
.subtitle{
  color: var(--grey-1, #8F939C);
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  letter-spacing: -0.24px;
}
.email{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  gap: 10px;
}
.email p{
  margin: 0;
}
.icon{
  margin: 0;
  padding: 0;
  filter: invert(57%) sepia(21%) saturate(849%) hue-rotate(95deg) brightness(97%) contrast(93%);
}