.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  padding-bottom: 0;
  z-index: 1;

  @media screen and (max-width: 638.98px) {
    padding: 0 0;
  }
}

.container {
  @media screen and (max-width: 800px) {
    padding: 0 14px;
  }
}
