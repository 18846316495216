.wrapper{
  display: flex;
  padding: 0 60px 20px 50px;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 1);
}
.contentWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.titleWrapper{
  margin-top: 240px;
  width: 40.60vw;
}
.title{
  color: white;
  font-size: 8.33vw;  
  line-height: 80%;
  font-family: Neue Machina;
  font-weight: 400;
  margin: 0;
  letter-spacing: -2.25px;
}
@media screen and (max-width: 540.22px){
  .title{
    font-size: 45px;
    line-height: 36px;  
  }
}
.line{
  width: 100%;
  border-top: 1px solid rgba(42, 42, 42, 1);
  margin: 35px 0 100px 0;
}
.bottomWrapper{
  display: flex;
  flex-direction: column;
  width: 1150px;
  margin-left: auto;
}
@media screen and (max-width: 1300px) {
  .bottomWrapper{
    width: 1050px;
  }
}
.button{
  display: flex;
  flex-direction: column;
  height: 100px;
}
.button span{
  font-size: 2.50vw;  
  line-height: 100%;
  font-weight: 300;
}

@media screen and (max-width: 960px) {
  .button span{
    font-size: 24px;
  }
}

.textWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;
}
.subTitle{
  margin: 0;
  color: white;
  font-size: 2.81vw;  
  line-height: 2.81vw;
  font-weight: 300;
  width: 30%;
}

@media screen and (max-width: 996.44px){
  .subTitle{
    font-size: 28px;
    line-height: 28px;
  }
}

.addition{
  margin: 0;
  color: rgba(143, 147, 156, 1);
  font-size: 0.94vw;  
  line-height: 0.94vw;
  margin-top: auto;
  width: 50%;
}

@media screen and (max-width: 1702.13px){
  .addition{
    font-size: 16px;
    line-height: 16px; 
  }
}

@media screen and (max-width: 1900px) {
  .titleWrapper{
    margin-top: 22vh;
  }
  .title{
    font-size: 5.33vw;
    line-height: 5.5vw;
  }
  .line{
    margin: 25px 0 75px 0;
  }
}
@media screen and (max-height: 666px) {
  .titleWrapper{
    margin-top: 150px;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper{
    padding: 0 20px 20px 20px;
  }
  .titleWrapper{
    width: 100%;
  }
  .bottomWrapper{
    height: 100%;
    width: 100%;
  }
  .textWrapper{
    flex-direction: column;
    gap: 15px;
  }
  .subTitle, .addition{
    width: 100%;
  }
  .button{
    margin-top: auto;
    height: 85px;
  }
}
@media screen and (max-width: 900px) {
  .line{
    margin: 40px 0 10px 0;
  }
  .titleWrapper{
    margin-bottom: 10px;
  }
  .bottomWrapper{
    margin-top: 40px;
  }
}
