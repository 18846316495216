.intro {
  padding: 0 60px 0 60px;
  margin-bottom: -145px;
}
.titles {
  margin-top: 305px;
  margin-bottom: 90px;
}
.title {
  font-family: Neue Machina;
  font-size: 7.48vw;
  line-height: 7vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
}
.rightTitle {
  text-align: right;
}
.centerTitle {
  text-align: left;
  margin-left: 15.63vw;
}

.introSubText {
  font-size: 1.56vw;
  line-height: 1.8vw;
  letter-spacing: -1px;
  text-align: center;
  color: black;

  margin-bottom: 25px;
}

@media screen and (max-width: 1282.05px) {
  .introSubText {
    font-size: 20px;
    line-height: 20px;
  }
}

.introCompany {
  font-size: 1.04vw;
  line-height: 1.25vw;
  text-align: center;
  color: var(--grey-1);
  letter-spacing: 0.1px;
}
@media screen and (max-width: 1538.46px) {
  .introCompany {
    font-size: 16px;
    line-height: 19.6px;
  }
}
.figureContainer {
  display: flex;
  position: relative;
  height: 38.28vw;
  width: calc(100% + 120px);
  margin-left: -60px;
}
.figureCircle {
  position: absolute;
  width: 150vw;
  height: 150vw;
  top: -40vw;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
  opacity: 0;
  transition: all 1s ease-out;
  border-radius: 50%;
}
@media screen and (max-width: 570.34px) {
  .figureCircle {
    top: -30vw;
  }
}
.onLoad {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
  opacity: 1;
}
.figure {
  position: absolute;
  width: 21.04vw;
  height: 21.04vw;
  background-color: var(--accent);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.13);
}

@media screen and (max-width: 570.34px) {
  .figure {
    font-size: 120px;
    line-height: 120px;
    width: 30vw;
    height: 30vw;
  }
}
.figure:nth-child(1) {
  bottom: 12%;
  left: 22%;
  transform: translateX(-50%);
  background-color: #4fa778;
}

@media screen and (max-width: 570.34px) {
  .figure:nth-child(1) {
    bottom: 12%;
    left: 18%;
  }
}
.figure:nth-child(2) {
  bottom: 3%;
  left: 40%;
  transform: translateX(-50%);
  background-color: #87c7ab;
}

@media screen and (max-width: 570.34px) {
  .figure:nth-child(2) {
    bottom: 0%;
    left: 39%;
  }
}

.figure:nth-child(3) {
  bottom: 3%;
  left: 60.6%;
  transform: translateX(-50%) rotate(23deg);
  background-color: #67b793;
}

@media screen and (max-width: 570.34px) {
  .figure:nth-child(3) {
    bottom: 0%;
    left: 62.6%;
  }
}

.figure:nth-child(4) {
  bottom: 12%;
  left: 77.5%;
  transform: translateX(-50%) rotate(40deg);
  background-color: #367755;
}

@media screen and (max-width: 570.34px) {
  .figure:nth-child(4) {
    bottom: 12%;
    left: 83%;
  }
}

.figure:nth-child(2) img {
  top: 55%;
  left: 38%;
}
.figure:nth-child(3) img {
  top: 40%;
  left: 56%;
}
.figure {
  right: 50%;
  transform: translateX(-50%);
}
.figureImage {
  position: absolute;
  height: 31.25vw;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 800px) {
  .intro {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 14px;
  }
  .title {
    font-size: 48px;
    line-height: 48px;
  }
  .titles {
    margin: 0 0 14px 0;
  }
  .centerTitle {
    margin: 0;
  }
}

@media screen and (max-width: 800px) {
  .intro {
    height: calc(100vh);
    margin-bottom: -50px;
  }
}

@media screen and (max-width: 481.28px) {
  .title {
    font-size: 36px;
    line-height: 36px;
  }
}
