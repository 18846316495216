.wrapper{
  display: flex;
  flex-direction: column;
  padding: 55px;
  border-radius: 15px;
  gap: 75px;
}
.subWrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
}
.inputs{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.passInput .textLabel {
  display: none;
}
.gigachad{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.input{
  display: flex;
  flex: 1 0;
  flex-direction: column;
}
.input .name{
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  letter-spacing: -0.32px;
  margin: 0;
}
.buttons{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.button{
  padding: 20px 25px;
}
.black{
  background-color: black;
  color: white;
}
.green{
  padding: 20px 57.5px;
}
.blue{
  background-color: #0088cc;
}

.modalTitle{
  text-align: center;
  font-family: Manrope;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%;
  letter-spacing: -1.44px;
}
.modalSubtitle{
  color: var(--grey-1, #8F939C);
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  letter-spacing: -0.24px;
}
.modalEmail{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  gap: 10px;
}
.modalEmail p{
  margin: 0;
}
.modalEmailIcon{
  filter: invert(57%) sepia(21%) saturate(849%) hue-rotate(95deg) brightness(97%) contrast(93%);
}

@media screen and (max-width: 1300px) {
  .subWrapper{
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  .wrapper{
    gap: 35px;
    padding: 55px 35px;
  }
  .buttons{
    flex-direction: column;
  }
  .button{
    width: 100%;
  }
  .gigachad{
    flex-direction: column;
  }
}
