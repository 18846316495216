/* HorizontalScrollContainer.module.css */

.verticalContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.horizontalContainer {
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.3s ease;
}

/* Если требуется скрыть горизонтальные полосы прокрутки */
.horizontalContainer::-webkit-scrollbar {
    display: none;
}
