.sliderWrapper {}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.9px;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--grey-3, #EBEFF4);
  cursor: pointer;
  background: var(--grey-5, #FAFAFB);
  background-image: linear-gradient(var(--accent), var(--accent));
  background-size: 40% 100%;
  background-repeat: no-repeat;
  padding: 0;
  transition: none !important;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 50px;
  width: 15px;
  background: var(--accent);
  border-radius: 5px;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 50px;
  width: 15px;
  background: var(--accent);
  border-radius: 5px;
  cursor: pointer;
}
input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 50px;
  width: 15px;
  background: var(--accent);
  border-radius: 5px;
  cursor: pointer;
}

/* input[type="range"]::-webkit-slider-thumb:hover { */
/*   background: #ff0200; */
/* } */
/* input[type="range"]::-moz-range-thumb:hover { */
/*   background: #ff0200; */
/* } */
/* input[type="range"]::-ms-thumb:hover { */
/*   background: #ff0200; */
/* } */

input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
input[type=range]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
