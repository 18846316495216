.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: #FFF;
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.18px;
}

.count {
  background: transparent;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.18px;
}

.count::-webkit-outer-spin-button,
.count::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.count {
  -moz-appearance: textfield;
  padding: 0;
}

.count:focus {
  border: none;
}

.button {
  cursor: pointer;
  user-select: none;
}
