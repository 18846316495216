.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  padding: 18px 20px;
  border-radius: 5px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--grey-5, #FAFAFB);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: -0.32px;
  transition: 0.2s ease-in-out;
}

input:disabled {
  color: var(--grey-1, #8F939C);
}

input:focus {
  border: 1px solid var(--accent);
}

input::placeholder {
  color: var(--grey-1, #8F939C);
}

.textLabel {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: -0.32px;
}

.inputLabel {
  position: relative;
}

.inputLabel svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
  transition: 0.2s ease-in-out;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error {
  color: #F46E86;
}

.rightLabel {
  color: var(--accent) !important;
}

.errorContainer input {
  border: 1px solid #F46E86;
}


.focusIcon svg {
  filter: invert(62%) sepia(52%) saturate(385%) hue-rotate(95deg) brightness(84%) contrast(86%);
}

.errorContainer svg {
  filter: invert(55%) sepia(6%) saturate(4820%) hue-rotate(301deg) brightness(103%) contrast(91%);
}

.requiredLabel::after {
  content: ' *';
  font-weight: 500;
  color: var(--accent);
}
