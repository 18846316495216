.wrapper{
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 100px;
  
  z-index: 1;
}
.title > p,
.subtitle > p,
.addition > p{
  margin: 0;
}
.title{
  font-size: 4.48vw;  
  line-height: 4.48vw;
  font-family: Neue Machina;
  font-weight: 500;
  width: 59%;
}
.right{
  margin-left: auto;
  margin-top: auto;
  width: 60%;
}
.subtitle{
  font-size: 1.25vw;  
  line-height: 1.51vw;
  margin: 0;
  font-weight: 500;
}
.addition{
  font-size: 1.25vw;  
  line-height: 1.51vw;
  color: #8F939C;
}
.line{
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.06);
}
.wrapper > .line{
  margin: 60px 0;
}
.right > .line{
  margin: 80px 0 15px 0;
}
.img{
  position: absolute;
  width: 250px;
  z-index: 0;
  top:5.87%;
  right: 20.47%;
  opacity: 0.4;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  .wrapper > .line{
    margin: 25px 0;
  }
  .right > .line{
    margin: 20px 0;
  }
}
@media screen and (max-width: 800px) {
  .right{
    width: 100%;
    margin-top: 0;
  }
}
@media screen and (max-width: 535.71px){
  .title{
    font-size: 24px;
    line-height: 24px;  
  }
}
@media screen and (max-width: 1440.00px){
  .subtitle{
    font-size: 18px;
    line-height: 22px;  
  }
}
@media screen and (max-width: 1280.00px){
  .addition{
    font-size: 16px;
    line-height: 19px;  
  }
}