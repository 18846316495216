.wrapper{
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  margin-top: 0
}
.bottom{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80%;
  height: 1080px;
  margin: 0 auto;
  z-index: 999;
}
.cards{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cards > div:nth-child(1){
  margin: 0 auto 0px 75px;
}
.cards > div:nth-child(2){
  margin: 0 30px 60px auto;
}
.line{
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.06);
}
.img{
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 1600px){
  .bottom{
    width: 80%;
  }
  .cards{
    gap: 30px;
  }
}
@media screen and (max-width: 1200px){
  .top{
    flex-direction: column;
  }
  .title, .right{
    width: 100%;
  }
  .right{
    margin-top: 25px;
  }
  .title{
    font-size: 60px;
    line-height: 60px;
  }
  .addition{
    margin-top: 20px;
  }
  .bottom{
    width: 90%;
  }
  .cards > div:nth-child(1){
    margin: 0 auto 0px 0;
  }
  .cards > div:nth-child(2){
    margin: 0 0 60px auto;
  }
}
@media screen and (max-width: 800px){
  .wrapper{
    padding: 0 10px;
  }
  .cards{
    gap: 60px;
  }
  .title{
    font-size: 24px;
    line-height: 24px;
  }
  .subtitle{
    font-size: 18px;
    line-height: 21.6px;
  }
  .addition{
    font-size: 16px;
    line-height: 19.2px;
  }
}