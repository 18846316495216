.buttonGroup {
  width: 100%;
}

.buttonList {
  display: flex;  
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.label {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
  .buttonList{
    gap: 10px;
  }
  .buttonList{
    justify-content: flex-start;
  }
  .buttonList button {
    padding: 10px 13px;
  }
}
