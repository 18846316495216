.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  width: 25%;
}
.document {
  position: relative;
  z-index: 0;
}
.img {
  width: 100%;
}
.number {
  position: absolute;
  top: -60px;
  right: 20px;
  z-index: -1;
  font-size: 75px;
  line-height: 75px;
  margin: 0;
  font-family: Neue Machina;
  font-weight: 800;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.22);
}
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 10px;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--accent);
  height: 3.91vw;
  width: 11.35vw;
  color: white;
  cursor: pointer;
  word-wrap: none;
}
.icon {
  height: 1.3vw;
  width: 1.3vw;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.type {
  color: #8f939c;
  font-size: 0.94vw;
  line-height: 0.94vw;
  font-family: Manrope;
  font-weight: 500;
  margin: 0;
}
.name {
  font-size: 1.67vw;
  line-height: 1.67vw;
  font-family: Neue Machina;
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
}
.text {
  margin: 0;
  font-size: 0.91vw;
  line-height: 0.91vw;
}
@media screen and (max-width: 1702.13px) {
  .type {
    font-size: 16px;
    line-height: 16px;
  }
}
@media screen and (max-width: 1437.13px) {
  .name {
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1483.52px) {
  .text {
    font-size: 13.5px;
    line-height: 13.5px;
  }
}
@media screen and (max-width: 1488.99px) {
  .button {
    width: 169px;
    height: 58px;
  }
}

@media screen and (max-width: 1200px) {
  .number {
    display: none;
  }
  .wrapper {
    margin: 0 auto;
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    width: 100%;
  }
}
