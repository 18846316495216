.chooseCoin {}

.coinItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
  cursor: pointer;
}

.itemsContainer {
  display: flex;
  gap: 35px;
}

.coinItem span {
  color: var(--grey-1, #8F939C);
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}

.label {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.32px;
  margin-bottom: 15px;
}
.coinItem:hover svg{
  filter: invert(56%) sepia(45%) saturate(417%) hue-rotate(95deg) brightness(94%) contrast(90%);
}

.coinItem:hover span{
  color: var(--accent, #4fa778);
}

.active svg{
  filter: invert(56%) sepia(45%) saturate(417%) hue-rotate(95deg) brightness(94%) contrast(90%);
}

.active span{
  color: var(--accent, #4fa778)
}