.wrapper{
  margin-top: 16.51vw;
  margin-bottom: 10.42vw;
  padding: 0 3.13vw;
}

@media screen and (max-width: 1009.57px){
  .wrapper{
    margin-top: 166.68px;
  }
}
@media screen and (max-width: 383.88px){
  .wrapper{
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 638.98px){
  .wrapper{
    padding: 0 20px;
  }
}

.titleWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 80px;
}
.title{
  font-size: 9.48vw;  
  line-height: 7.58vw;
  font-weight: 400;
  font-family: Neue Machina;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: -3px;
}

@media screen and (max-width: 569.62px){
  .title{
    font-size: 54px;
    line-height: 43.2px;  
  }
}

.addition{
  margin: 0;
  margin-top: auto;
  font-size: 1.25vw;
  line-height: 1.25vw;
  color: var(--grey-1);
  width: 23.18vw;
}

@media screen and (max-width: 1280.00px) {
  .addition{
    font-size: 16px;
    line-height: 16px;  
  }
}

.contactWrapper{
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 66vw;
  margin-left: auto;
}
.leftWrapper{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.inputWrapper{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.number{
  color: rgba(206, 208, 214, 1);
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}
.inputSubWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputTitle{
  font-size: 0.94vw;  
  line-height: 0.94vw;
  font-weight: 600;
}
@media screen and (max-width: 1702.13px){
  .inputTitle{
    font-size: 16px;
    line-height: 16px; 
  }
}
.input{
  background: none;
  padding-left: 0;
}
.button{
  margin-top: 55px;
  border-radius: 5px;
}
.rightWrapper{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.rightWrapper > .inputWrapper,
.rightWrapper > .button{
  margin-left: 70px;
}
@media screen and (max-width: 1200px) {
  .rightWrapper > .inputWrapper,
  .rightWrapper > .button{
    margin-left: 0;
  }
}
.rightWrapper > .inputWrapper{
  margin-bottom: auto;
}
.line{
  width: 100%;
  border-top: 1px solid rgba(206, 208, 214, 1);
}


@media screen and (max-width: 1200px) {
  .contactWrapper{
    flex-direction: column;
    width: 100%;
  }
  .leftWrapper,.rightWrapper{
    width: 100%;
  }
  .number{
    display: none;
  }
  .rightWrapper > .line:nth-child(1){
    display: none;
  }
}

textarea {
  border: none;
  outline: none;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: -0.32px;
  transition: 0.2s ease-in-out;
}
