.wrapper{
  display: flex;
  flex-direction: row;
  
  padding: 55px 45px 55px 45px;
  gap: 11.31px;
  color: white;
  width: 33%;
  min-width: 300px;
  z-index: 10;
  position: relative;
}
.background{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 17px;
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  background: rgba(255, 255, 255, 0.15);
}
.number{
  font-size: 3.39vw;  
  line-height: 3.39vw;
  font-family: Neue Machina;
  font-weight: 700;
  margin-top: -5px;
  margin-left: 5px;
  margin-right: 50px;
}
.content{
  display: flex;
  flex-direction: column;
  gap: 30px
}
.title{
  font-size: 0.94vw;  
  line-height: 1.15vw;
  font-weight: 500;
}
.description{
  font-size: 1.15vw;  
  line-height: 1.35vw;
  font-weight: 600;
  margin-top: auto;
}
@media screen and (max-width: 1200px){
  .wrapper{
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
}
@media screen and (max-width: 943.95px){
  .number{
    font-size: 32px;
    line-height: 19px;  
  }
}
@media screen and (max-width: 1702.13px){
  .title{
    font-size: 16px;
    line-height: 19px;  
  }
}
@media screen and (max-width: 1565.22px){
  .description{
    font-size: 18px;
    line-height: 22px;  
  }
}
