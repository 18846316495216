.intro {
  display: flex;
  position: relative;
  padding: 0 3.13vw;
  height: calc(100vh - 50px);
  background: var(--accent) url(/src/assets/images/glassy-5.png) no-repeat 100%;
  background-size: cover;
}

.titleWrapper {
  width: 100%;
  margin-top: 37.78vh;
  margin-top: auto;
  margin-bottom: 27vh;
}
.title {
  color: white;
  font-family: Neue Machina;
  font-style: normal;
  font-weight: 400;
  font-size: 7.5vw;
  letter-spacing: -3px;
  line-height: 6.7vw;
  text-transform: uppercase;
}
.title.titleLeft {
  text-align: left;
}
.title.titleRight {
  text-align: right;
}
.title.titleCenter {
  margin-right: 380px;
  text-align: center;
}

.bottom {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  padding-bottom: 9.81vh;
}
.text {
  color: white;
  font-family: Manrope;
  font-size: 1.67vw;
  line-height: 1.67vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 32.97vw;
  margin-left: 45px;
}
.buttonWrapper {
  position: absolute;
  left: 40px;
  top: 30px;
}
.button {
  padding: 0 !important;
}

@media screen and (max-width: 1077.84px) {
  .text {
    font-size: 18px;
    line-height: 18px;
  }
}
@media screen and (max-width: 480px) {
  .title {
    font-size: 36px;
    line-height: 36px;
  }
}

@media screen and (max-width: 500px) {
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buttonWrapper {
    margin: 0;
  }
}

@media screen and (max-width: 1200px) {
  .intro {
    padding: 0 20px;
  }
  .bottom {
    flex-direction: column;
    align-items: center;
  }
  .text {
    width: fit-content;
    margin: 5vh 20px;
  }
  .buttonWrapper {
    position: static;
  }
}

@media screen and (max-width: 638.98px) {
  .titleWrapper {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;

  }
  .title {
    font-size: 42px;
    letter-spacing: 0;
    line-height: 0.9em;
  }
  .intro {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 100vh 100vh;
    background-position: center;
  }
  .bottom {
    flex-direction: column;
    align-items: center;
    position: initial;
    transform: none;
    left: auto;
  }
  .text {
    width: fit-content;
    margin: 5vh 20px;
  }
  .buttonWrapper {
    position: absolute;
    bottom: 14px;
    top: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
