.intro {
  position: relative;
  padding: 0 24px;
  height: calc(100vh - 50px);
  background: var(--accent) url(/src/assets/images/glassy-5.png) no-repeat 100%/105%;
  background-size: cover;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 10%;
  position: absolute;
  left: 50%;
  top: 29%;
  transform: translate(-50%, -50%);
}

.title {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -3px;
  text-align: left;
  color: white;
  font-family: Neue Machina;
  font-style: normal;
  font-weight: 400;
  /* letter-spacing: -12.227px; */
  text-transform: uppercase;

  /*
  @media screen and (max-height: 900px) {
    font-size: 136px;
    line-height: 6rem;
  }
  */
}
.title:nth-child(2) {
  /*margin-left: 13.8vw;*/
}

.bottom {
  display: flex;
  position: absolute;
  left: 60px;
  right: 60px;
  bottom: 110px;
}

.numberWrapper {
  color: white;
  font-family: Neue Machina;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.24px;
  text-transform: uppercase;
  flex: 0 1 310px;
}

.textWrapper {
  color: white;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 20px;
  flex: 0 1 325px;
}

.buttonWrapper {
  margin-left: auto;
}

.button {
  position: absolute;
  right: -1%;
  top: 20%;
  padding: 0 !important;
  border-radius: 50% !important;
}

@media screen and (max-width: 500px) {
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .numberWrapper {
    display: none;
  }

  .buttonWrapper {
    margin: 0;
  }
}

@media screen and (max-width: 800px) {
  .intro {
    background-size: auto 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center;
  }

  .titleWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    position: static;
    transform: none;
  }
  .numberWrapper {
    flex: auto;
  }
  .textWrapper {
    flex: auto;
  }

  .bottom {
    display: flex;
    position: initial;
    left: auto;
    right: auto;
    bottom: auto;
  }

  .button {
    position: static;
    right: auto;
    top: auto;
    padding: 0 !important;
    border-radius: 50% !important;
  }

  .buttonWrapper {
    bottom: 14px;
    position: absolute;
  }
}

@media screen and (max-width: 900px) {
  .titleWrapper{
    padding-top: 100px;
  }
  .bottom{
    gap: 30px;
  }
}

@media screen and (max-width: 339.2px) {
  .title{
    font-size: 54px;
    line-height: 52.11px;
  }
}
