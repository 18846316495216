.wrapper{
  width: 400px;
}

.title,.subtitle,.addition{
  margin: 0;
}

.title{
  color: var(--dark, #00021A);
  /* Heading 1 */
  font-family: Manrope;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  letter-spacing: -2.08px;
  width: 90%;
  margin-bottom: 30px;
}
.subtitle{
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 28.8px */
  letter-spacing: -1.28px;
  margin-bottom: 15px;
}
.addition{
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 95%; /* 17.1px */
  letter-spacing: -0.72px;
  margin-bottom: 60px;
}
.button{
  width: 100%;
}
@media screen and (max-width:1200px) {
  .wrapper{
    width: 274px;
    margin: 0 10px;
  }
  .title{
    color: var(--dark, #001007);
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.96px;
    margin-bottom: 25px;
  }
  .subtitle{
    color: var(--dark, #001007);
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -0.8px;
    margin-bottom: 10px;
  }
  .addition{
    color: var(--grey-1, #8F939C);
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.72px;
    width: 75%;
    margin-bottom: 25px;
  }
}