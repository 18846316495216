.roadmap {
  margin-top: 210px;
  margin-bottom: 200px;
}

@media screen and (max-width: 638.98px) {
  .roadmap {
    margin: 0;
    padding: 60px 0;
  }
}

.roadmapHeading {
  text-align: left;
}
.roadmapHeading:nth-child(2) {
  margin-left: 230px;
  color: var(--accent);
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.titleWrapper {
  width: 62%;
}
.title {
  font-size: 9.48vw;
  line-height: 7.7vw;
  text-transform: uppercase;
  font-family: Neue Machina;
  font-weight: 400;
  letter-spacing: -3px;
  margin: 0;
  padding: 0 5px;
}
.title:nth-child(1) {
  text-align: left;
}
.title:nth-child(2) {
  text-align: right;
}
@media screen and (max-width: 379.75px) {
  .title {
    font-size: 36px;
    line-height: 36px;
  }
}

.years {
  color: black;
  text-align: right;
  font-family: Neue Machina;
  font-size: 4.17vw;
  line-height: 4.17vw;
  font-style: normal;
  font-weight: 500;
  width: 28%;
}

.cards {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 40px;
  position: relative;
}

.cardsList {
  width: 100%;
  padding: 14px;
}

@media screen and (max-width: 800px) {
  .cardsList {
    padding: 0;
  }
}

.buttons {
  /* display: none; */
  margin-top: 20px;
  display: flex;
  gap: 10px;
  padding: 0 24px;
}
.button_download{
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.button_download button{
  background: var(--accent);
  color: white;
  padding: 30px 70px;
}
.button_download button span{
  font-size: 22px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}

.button:nth-child(1) {
  border: 1px solid black;
}
.button:nth-child(2) {
  margin-left: 10px;
  background-color: var(--accent);
}

.button:nth-child(2) svg path {
  stroke: #fff;
}

.roadmapButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
}

@media screen and (max-width: 800px) {
  .roadmapButtons {
    justify-content: space-between;
  }
}

.buttonYears {
  display: none;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

@media screen and (min-width: 800px) {
  .cards {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .head {
    flex-direction: column;
    align-items: normal;
  }
  .roadmapHeading {
    font-size: 36px;
    line-height: 34.74px;
  }
  .roadmapHeading:nth-child(2) {
    margin-left: 52.82px;
  }
  .years {
    display: none;
  }

  .buttonYears {
    display: flex;
  }

  .cards {
    justify-content: center;
    right: 0;
    margin-top: 30px;
  }
}
