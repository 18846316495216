.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  margin-left: 100px;
  gap: 3%;
}
.content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 30px;
  position: relative;
}
.title{
  margin-top: 55px;
  font-size: 1.46vw;  
  line-height: 1.46vw;
  margin-right: 0px;
  margin-left: 200px;
}
.cards{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 80px;
  gap: 10px;
}
.card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.31);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  border-radius: 17px;
  padding: 55px 80px 55px 45px;
  gap: 30px;
  height: 160px;
  width: 50%;
}
.description{
  font-size: 0.94vw;  
  line-height: 1.15vw;
  width: 34%;
}
.value{
  width: 60%;
  text-align: right;
  font-weight: 700;
  font-size: 3.01vw;  
  line-height: 3.01vw;
  margin-left: auto;
  color: var(--accent);
  font-family: Neue Machina;
}
.line{
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.06);
  margin: 60px 0 100px 0;
}
.abstract1 {
  position: absolute;
  top: 80px;
  right: 165px;
}
.abstract2 {
  position: absolute;
  top: -70px;
  left: 552px;
}

@media screen and (max-width: 1200px) {
  .wrapper{
    margin-left: 0;
  }
  .content{
    flex-direction: column;
    padding-right: 0px;
  }
  .cards{
    flex-direction: column;
    padding-right: 0px;
  }
  .description{
    width: 50%;
    margin: 0;
  }
  .value{
    width: 50%;
    margin: 0;
  }
  .card{
    width: 100%;
    height: 98px;
    padding: 30px;
    gap: 0;
  }
  .title{
    width: 100%;
    margin-right: auto;
    margin-left: 0;
  }
  .line{
    margin: 20px 0;
  }
  .abstract1 {
    display: none;
  }
  .abstract2 {
    display: none;
  }
}
@media screen and (max-width: 1063.12px){
  .value{
    font-size: 32px;
    line-height: 32px;  
  }
}
@media screen and (max-width: 1232.88px){
  .title{
    font-size: 18px;
    line-height: 18px;  
  }
}
@media screen and (max-width: 1702.13px){
  .description{
    font-size: 16px;
    line-height: 19px;  
  }
}
