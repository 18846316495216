.header__desktop {
  width: 100%;
  padding: 35px 55px 35px 55px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--grey-3, #EBEFF4);
}

.burgerContainer {
  display: none;
}

@media screen and (max-width: 1543px) {
  .burgerContainer {
    display: block;
  }
}

.section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.button {
  border-radius: 10px;
}

.darkOutline {
  color: var(--dark) !important;
}

.iconButton {
  padding: 20px !important;
}

.langSelector {
  border-radius: 10px;
  border: 1px solid var(--grey-3);
  padding: 20px;
  margin: 0;

  @media screen and (max-width: 1600px) {
    background: #F4F5F7;
  }
}

.header__mobile {
  display: none;
  flex-direction: column;
  gap: 5px;
  padding: 15px 20px 40px 20px;
}

.row, .rowSide {
  display: flex;
  gap: 5px;
  align-items: center;
}

.row {
  justify-content: space-between;
}

.top {}
.mid {}

.mid .infoBox {
  padding: 10px;
  justify-content: center;
}

.mid .infoBox:nth-child(1) {
  flex: 1 1 40%;
}

.mid .infoBox:nth-child(2) {
  flex: 1 1 60%;
}

.bottom {}

.button__mobile {
  width: 100%;
  display: none;
}

.mobBox {
  padding: 19px 15px;
  border-radius: 10px;
  border: 1px solid #EBEFF4;
  background: #F4F5F7;
}

@media screen and (max-width: 1475px) {
  .header__desktop {
    padding: 30px;
  } 
}

@media screen and (max-width: 1200px) {
  .header__desktop {
    display: none;
    padding: 35px;
  } 

  .header__mobile {
    display: flex;
  }

  .button__mobile {
    display: flex;
  }
}

@media screen and (max-width: 805px) {
  .burgerContainer {
    display: none;
  }

  .langSelector {
    font-size: 14px;
    padding: 14px !important;
  }

  .logo3 {
    padding: 19px 15px;
    max-width: 156px;
    width: 100%;
  }

  .iconButton {
    padding: 14px !important;
  }
}
