:root {
  /* fonts */
  --font-neue-machina: "Neue Machina";
  --font-manrope: Manrope;

  /* font sizes */
  --font-size-61xl: 80px;
  --font-size-41xl: 60px;
  --font-size-lg: 18px;
  --font-size-13xl: 32px;
  --font-size-xl: 20px;
  --font-size-163xl: 182px;
  --font-size-5xl: 24px;
  --font-size-sm: 14px;
  --font-size-136xl-8: 155.8px;
  --font-size-141xl: 160px;
  --font-size-base: 16px;
  --font-size-42xl: 61px;

  /* Colors */
  --accent: #4fa778;
  --white: #fff;
  --color-gray-100: #8e8e8e;
  --color-gray-200: #101010;
  --dark: #001007;
  --color-gray-300: #010d00;
  --color-gray-400: rgba(255, 255, 255, 0.44);
  --color-gray-500: rgba(255, 255, 255, 0.2);
  --color-gray-600: rgba(255, 255, 255, 0.42);
  /* --grey-1: #8f939c; */
  --grey-1: #626466;
  --color-black: #000;
  --color-darkslategray: #343434;
  --color-silver: #bebebe;
  --grey-3: #ebeff4;
  --color-darkseagreen: #92c9ab;

  --color-seed: #87C7AB;
  --color-vega: #67B793;
  --color-canna-hold: #6F7DD7;
  --color-bloom: #489B6D;
  --color-harvest: #367755;
  --color-shareholder: #26543D;

  /* Gaps */
  --gap-xl: 20px;
  --gap-91xl: 110px;
  --gap-31xl: 50px;
  --gap-11xl: 30px;
  --gap-3xs: 10px;
  --gap-56xl: 75px;
  --gap-692xl: 711px;
  --gap-121xl: 140px;
  --gap-8xs: 5px;
  --gap-mini: 15px;
  --gap-101xl: 120px;
  --gap-21xl: 40px;
  --gap-10xs: 3px;
  --gap-201xl: 220px;
  --gap-114xl: 133px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-6xl: 25px;
  --padding-11xl-2: 30.2px;
  --padding-31xl: 50px;
  --padding-41xl: 60px;
  --padding-12xl: 31px;

  /* border radiuses */
  --br-11xl: 30px;
  --br-3xs: 10px;
  --br-101xl-7: 120.7px;
  --br-mini: 15px;

  /* Effects */
  --blur: blur(20px);
}
