.wrapper{
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 3.13vw;
  padding-bottom: 100px;
  z-index: 1;
}

@media screen and (max-width: 638.98px){
  .wrapper{
    padding: 0 20px;
  }
}
.img{
  position: absolute;
  width: 16vw;
  z-index: 0;
  top: 5%;
  left: 65%;
  z-index: -1;
}
.img3{
  position: absolute;
  top: 30%;
  left: 53%;
  height: 22vw;
  z-index: -1;
}
.letters{
  display: flex;
  justify-content: center;
  position: relative;
  height: 250px;
  width: 100%;
}
.ourProductsText{
  font-size: 3.96vw;
  line-height: 3.96vw;
  text-align: center;
  width: 100%;
  letter-spacing: 4px;
  margin-top: 80px;
  margin-bottom: 150px;
  text-transform: uppercase;
}

@media screen and (max-width: 606.06px){
  .ourProductsText{
    font-size: 24px;
    line-height: 24px;  
  }
}

@media screen and (max-width: 800px) {
  .img{
    top:0%;
    width: 125px;
  }
  .img3{
    bottom: -75px;
    height: 125px;
  }
  .letters{
    height: 100px;
    margin-bottom: 100px;
  }
}
