.card {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  width: 23.07vw;
  height: 43.18vw;
  margin-right: 10px;
  padding: 3.8vw 3.3vw 0 3.3vw;
  border-radius: 30px;
  background-color: white;

  @media screen and (max-width: 1452.1px) {
    width: 335px;
  }
  @media screen and (max-width: 1236.68px) {
    height: 534px;
  }
  @media screen (max-width: 1079.14px) {
    padding-top: 45px;
  }
  @media screen and (max-width: 1086.96px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 800px) {
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: inherit;
  background-color: inherit;
  pointer-events: none;
}
.number {
  z-index: -2;
  position: absolute;
  right: 20px;
  top: -7%;
  font-size: 3.92vw;
  line-height: 3.92vw;
  margin: 0;
  font-family: Neue Machina;
  font-weight: 800;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 2px rgba(1, 13, 0, 0.22);

  @media screen and (max-width: 918.37px) {
    font-size: 36px;
    line-height: 36px;
  }
}
.title {
  font-size: 1.98vw;
  line-height: 1.98vw;
  font-weight: 500;
  letter-spacing: 1px;
  height: 15%;
  width: 110%;
  margin: 0;

  @media screen and (max-width: 1212.12px) {
    font-size: 24px;
    line-height: 24px;
  }
  @media screen and (max-width: 1086.96px) {
    width: 100%;
  }
}
.description {
  font-size: 0.94vw;
  line-height: 1.22vw;

  width: 110%;

  @media screen and (max-width: 1702.13px) {
    font-size: 16px;
    line-height: 20.8px;
  }

  @media screen and (max-width: 1086.96px) {
    width: 100%;
  }
}
.btnWrapper {
  display: flex;
  align-items: flex-end;
  flex: 1 0 auto;
  /* padding-bottom: 300px; */
  padding-bottom: 20vw;

  @media screen and (max-width: 1560px) {
    padding-bottom: 250px;
  }
}
.button {
  width: fit-content;
  font-size: 18px;
  line-height: 24.59px;
  letter-spacing: -2%;
}
.buttonText {
  margin: 4px 0;
}
.image {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 28vw;
  min-width: 330px;
  bottom: -34%;

  @media screen and (max-width: 1057.27px) {
    width: 330px;
  }
}

