body {
  margin: 0;
  line-height: normal;
  font-size: 16px;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--dark);
}

.App {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

h1,
h2,
h3 {
  margin: 0;
}

main {
  overflow: hidden;
  background: #ebebeb;
}

.heading {
  text-transform: uppercase;
  color: black;
  text-align: center;
  font-size: 160px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
}

@media screen and (max-width: 1500px) {
  .heading {
    font-size: 60px;
  }
}

.accent-text {
  color: var(--accent);
}
.bold-text {
  font-weight: 800;
}
.white-accent-text {
  color: var(--white);
}
.bold-accent-text {
  color: black;
  font-weight: 800;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 50px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

.productsSwiper {
  overflow: inherit !important;
}

section.horizontal {
  overflow-x: hidden;
}

section.horizontal .pin-wrap,
section.horizontal .animation-wrap {
  display: flex;
  position: relative;
  z-index: 1;
}

section.horizontal .item {
  position: relative;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  background-color: transparent;
}

section.horizontal .animation-wrap.to-right {
  counter-reset: item;
  float: left;
  display: flex;
  gap: 24px;
  padding: 0 120px;
}

section.horizontal .animation-wrap.to-left {
  counter-reset: item 11;
  float: right;
}

section.horizontal .animation-wrap.to-right .item:before {
  counter-increment: item;
  content: counter(item);
}

section.horizontal .animation-wrap.to-left .item:before {
  counter-increment: item -1;
  content: counter(item);
}

section.horizontal .animation-wrap .item:nth-child(2n + 2) {
  align-items: flex-start;
}

section.horizontal .animation-wrap .item:nth-child(4n + 4) {
  align-items: flex-end;
}

.swiper-slide {
  height: auto !important;
}

/* loader */

.lds-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* font-family: "Neue Machina"; */
  font-weight: 500;
  font-size: 36px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 5px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
