.title{
  color: var(--accent, #4FA778);
  text-align: center;
  font-family: Manrope;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 95%; /* 53.2px */
}
.subtitle{
  color: var(--grey-1, #8F939C);
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}
.circle{
  width: 165px;
  height: 165px;
  background-color: var(--accent);
  border-radius: 100%;
  position: relative;
  margin: 40px auto 0;
}
.circle img{
  position: absolute;
  width: 90%;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%) ;
}

@media screen and (max-width: 1200px) {
  .title{
    font-size: 24px;
  }
  .circle{
    width: 130px;
    height: 130px;
  }
}
