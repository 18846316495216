.transactionHistory {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--white, #FFF);
  padding: 55px;
  @media screen and (max-width:1200px) {
    padding: 30px;
  }
}
.buttons{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}

.button{
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background-color: var(--grey-4, #F4F5F7);

  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}
.button span::after{
  background-color: var(--accent);
}
.button:hover{
  color: white;
}
.button.active{
  background-color: var(--accent);
  color: white;
}