.advantages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  min-height: 40vw;
  width: 100%;
  padding: 110px 60px 120px 60px;
  margin-top: 240px;
  border-radius: 3.13vw;
  background-color: var(--accent);
}
@media screen and (max-width: 800px) {
  .advantages {
    margin-top: 40px;
  }
}

@media screen and (max-width: 479.23px) {
  .advantages {
    border-radius: 15px;
  }
}
.textWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.firstText {
  font-size: 3.68vw;
  line-height: 3.68vw;
  font-weight: 500;
  letter-spacing: 0px;
  font-family: Neue Machina;
}
@media screen and (max-width: 652.17px) {
  .firstText {
    font-size: 24px;
    line-height: 24px;
  }
}
.secondText {
  font-size: 1.04vw;
  line-height: 1.15vw;
  font-weight: 500;
  margin-top: 20px;
  color: white;
}

@media screen and (max-width: 1730.77px) {
  .secondText {
    font-size: 18px;
    line-height: 19.8px;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  position: relative;
  min-width: 711px;
  width: 50%;
  height: 100%;
  gap: 20px;
  z-index: 1;
}
.infoImg {
  position: absolute;
  top: 50%;
  left: 78.4%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

@media screen and (max-width: 1200px) {
  .advantages {
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  .infoWrapper {
    width: 100%;
    margin-bottom: 30px;
    min-width: 0;
  }
  .infoImg {
    top: 70%;
    left: 50%;
  }
  .textWrapper {
    width: 100%;
  }
}
.letters {
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  top: 100%;
  left: 50%;
}
