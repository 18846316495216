.infoBox {
  padding: 20px 25px;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--grey-4, #F4F5F7);
  display: flex;
  gap: 3px;
  align-items: center;
}

.text {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.icon {}

@media screen and (max-width: 805px) {
  .text {
    font-size: 14px;
  }
}
