.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
}
.modal {
  padding: 55px;
}
.title{
  color: var(--dark);
  font-family: Manrope;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 95%; /* 53.2px */
  text-align: left;
  margin-top: 20px;
}
.subtitle{
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  text-align: left;
  margin: 0;
}
.bottom {
}

.bottom button {
  width: 100%;
}

.circle img{
  position: absolute;
  width: 90%;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%) ;
}

@media screen and (max-width: 1200px) {
  .title{
    font-size: 24px;
  }
  .circle{
    width: 130px;
    height: 130px;
  }
}
