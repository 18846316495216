.wrapper{
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 100px;
  
  z-index: 1;
}
.title > p,
.subtitle > p,
.addition > p{
  margin: 0;
}
.title{
  font-size: 4.48vw;  
  line-height: 4.48vw;
  font-family: Neue Machina;
  font-weight: 500;
  width: 40%;
}
.right{
  margin-left: auto;
  margin-top: auto;
  padding-top: 10px;
  width: 66.5%;
}
.subtitle{
  font-size: 2.71vw;  
  line-height: 3vw;
  letter-spacing: 3px;
  margin: 0;
  font-weight: 500;
}

@media screen and (max-width: 664.21px){
  .subtitle{
    font-size: 18px;
    line-height: 18px; 
  }
}

.addition{
  font-size: 1.15vw;  
  line-height: 1.15vw;
  color: #8F939C;
}

@media screen and (max-width: 1391.30px){
  .addition{
    font-size: 16px;
    line-height: 16px;  
  }
}

.line{
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.06);
}
.wrapper > .line{
  margin: 60px 0;
}
.right > .line{
  margin: 90px 0 25px 0;
}
.img{
  position: absolute;
  width: 250px;
  z-index: 0;
  top:5.87%;
  right: 20.47%;
  opacity: 0.4;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  .wrapper > .line{
    margin: 25px 0;
  }
  .right > .line{
    margin: 20px 0;
  }
}
@media screen and (max-width: 800px) {
  .right{
    width: 100%;
    margin-top: 0;
  }
}
@media screen and (max-width: 535.71px){
  .title{
    font-size: 24px;
    line-height: 24px;  
  }
}
@media screen and (max-width: 1440.00px){
  .subtitle{
    font-size: 18px;
    line-height: 22px;  
  }
}
@media screen and (max-width: 1280.00px){
  .addition{
    font-size: 16px;
    line-height: 19px;  
  }
}
