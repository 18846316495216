.wrapper{
  margin-right: calc(31.61vw / 2);
  margin-left: calc(31.61vw / 2);
}

@media screen and (max-width: 1200px){
  .wrapper{
    margin-left: 0px;
  }
}

.title{
  font-size: 1.35vw;  
  line-height: 1.49vw;
  color: var(--accent);
  font-weight: 500;
  font-family: Neue Machina;
}

@media screen and (max-width: 1629.63px){
  .title{
    font-size: 22px;
    line-height: 24.2px;
  }
}

.description{
  font-size: 1.15vw;  
  line-height: 1.26vw;
  color: var(--grey-1);
}

@media screen and (max-width: 1391.30px){
  .description{
    font-size: 16px;
    line-height: 17.6px;  
  }
}
