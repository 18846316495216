.card{
  display: flex;
  flex-direction: row;
  min-width: 509px;
  background: rgba(255, 255, 255, 0.31);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  border-radius: 17px;
  padding: 55px 45px 55px 45px;
  gap: 11.31px;
}
.number{
  font-size: 3.39vw;  
  font-family: Neue Machina;
  line-height: 3.39vw;
  font-weight: 700;
  margin-bottom: auto;
}
.right{
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  max-width: 286px;
  margin-left: 50px;
}
.title{
  font-size: 1.15vw;  
  line-height: 1.15vw;
  font-weight: 900;
  margin: 0;
}
.description{
  font-size: 0.83vw;  
  line-height: 0.83vw;
  font-weight: 400;
  color: #4B4B4B;
  margin: auto 0 0 0;
}
@media screen and (max-width: 1200px) {
  .card{
    flex-direction: column;
    width: 60%;
    min-width: 283px;
    height: fit-content;
  }
  .right{
    margin-left: 0;
  }
}
@media screen and (max-width: 800px){
  .card{
    flex-direction: column;
    width: 60%;
    min-width: 283px;
    height: fit-content;
  }
  .description{
    font-size: 0.83vw;  
    line-height: 0.83vw;
    margin-top: 15px;
  }
}
@media screen and (max-width: 1565.22px){
  .title{
    font-size: 18px;
    line-height: 18px;  
  }
}
@media screen and (max-width: 943.95px){
  .number{
    font-size: 32px;
    line-height: 32px;  
  }
}
@media screen and (max-width: 1927.71px){
  .description{
    font-size: 16px;
    line-height: 16px;  
  }
}
