.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 200px;
}
.cards {
  display: flex;
  flex-direction: row;
  gap: 25px;
  width: 100%;
}

.slides {
  width: 100%;
  display: none;
}

.buttons {
  display: none;
  margin-top: 20px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.nowCardHandler {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.buttonYears {
  display: none;
  margin: 0;
  font-size: 75px;
  line-height: 75px;
  font-family: Neue Machina;
  font-weight: 800;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.22);
}
.modal {

}
.modal img {
  height: 90vh;

}

@media screen and (max-width: 1200px) {
  .buttons {
    display: flex;
  }
  .buttonYears {
    display: flex;
  }
  .button:nth-child(1) {
    transform: rotate(180deg);
    border: 1px solid black;
  }
  .button:nth-child(2) {
    margin-left: 10px;
    background-color: var(--accent);
  }
  .cards {
    justify-content: center;
    right: 0;
    display: flex;
  }
  .cards > div {
    display: none;
  }
  .cards > div:nth-child(1) {
    display: flex;
  }
  .buttons {
    margin: 0;
  }
  .titleWrapper {
    margin: 50px 0;
  }
  .cards {
    display: none;
  }
  .slides {
    width: 100%;
    display: block;
  }
  .buttonYears {
    -webkit-text-stroke: 1px rgb(171 171 171);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 50.4px */
    letter-spacing: 1.8px;
  }
}

@media screen and (max-width: 800px) {
  
}
