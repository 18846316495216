.wrapper{
  margin-top: 16.51vw;
  margin-bottom: 10.42vw;
  padding: 0 3.13vw;
}
@media screen and (max-width: 1009.57px){
  .wrapper{
    margin-top: 166.68px;
  }
}
@media screen and (max-width: 383.88px){
  .wrapper{
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 638.98px){
  .wrapper{
    padding: 0 20px;
  }
}

.title{
  font-size: 7.48vw;  
  line-height: 7.58vw;
  font-weight: 500;
  font-family: Neue Machina;
  text-transform: uppercase;
  margin: 0;

  @media screen and (max-width: 569.62px){
    font-size: 54px;
    line-height: 43.2px;  
  }
}

.line{
  margin-top: 2.34vw;
  margin-bottom: 5.99vw;
  border-top: 1px solid #D6D6D6 ;
}

@media screen and (max-width: 1068.38px){
  .line{
    margin-top: 25px;
  }
}
@media screen and (max-width: 667.78px){
  .line{
    margin-bottom: 40px;
  }
}
