.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 315px;
}
.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 999;
  margin-left: -130px;
}
.title {
  font-size: 9.48vw;
  line-height: 9.48vw;
  font-weight: 400;
  font-family: Neue Machina;
  text-transform: uppercase;
  letter-spacing: -3px;
  margin-bottom: 77px;

  @media screen and (max-width: 379.75px) {
    font-size: 36px;
    line-height: 36px;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 3vw;
  height: 100%;
  width: 100%;
}
.cards > div:nth-child(1n) {
  margin: 0 auto 0 auto;
}
.cards > div:nth-child(2n) {
  margin: 1.6vw auto 0 13vw;
}
@media screen and (max-width: 600px) {
  .cards > div:nth-child(2n) {
    margin: 0 auto 0 0;
  }
}
.cards > div:nth-child(3n) {
  margin: -1.9vw 2vw 0 auto;
}
.img {
  position: absolute;
  top: 64%;
  left: 47%;
  height: 50.23vw;
  width: 50.23vw;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;

  @media screen and (max-width: 562.11px) {
    height: 243px;
    width: 243px;
  }
}

@media screen and (max-width: 1200px) {
  .bottom {
    margin-left: 0;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    margin: 40px 0;
  }
}
