.card{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 17.97vw;
  height: 9.38vw;
  border-radius: 17px;
  border: 1.13px solid rgba(255,255,255, 0.29);
  padding: 3.07vw 44px 3.07vw 44px;
  gap: 11.31px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
  color: white;
  z-index: 1;
}
@media screen and (max-width: 977.20px){
  .card{
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 1530.33px){
  .card{
    width: 275px;
  }
}
@media screen and (max-width: 1066.10px){
  .card{
    height: 100px;
  }
}
.title{
  font-size: 3.39vw;  
  line-height: 3.27vw;
  color: white;
  font-weight: 700;
  font-family: Neue Machina;
  margin-left: -5px;
  margin-right: -30px;
  width: 30%;
}

@media screen and (max-width: 943.95px){
  .title{
    font-size: 32px;
    line-height: 30.88px;  
  }
}

.subtitle{
  font-size: 1.25vw;  
  line-height: 1.38vw;
  font-weight: 400;
  width: 50%;
  margin-left: 55px;
  color: white;
  font-family: Manrope;
}
@media screen and (max-width: 1440.00px){
  .subtitle{
    font-size: 1.25vw;  
    font-size: 18px;
    line-height: 19.8px;  
  }
}

@media screen and (max-width: 1200px){
  .card{
    width: 100%;
  }
}
