.intro {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: var(--accent) url(/src/assets/images/glassy-1.png) no-repeat 100%;
  background-size: cover;
}

@media screen and (max-width: 408.16px) {
  .intro {
    background-position: center center;
  }
}

.title {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 4.33vw;
  line-height: 4.5vw;
  letter-spacing: 1px;
  padding: 190px 0 0 0;
  font-family: "Neue Machina";
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 1900px) {
  .title {
    font-size: 5vw;
    line-height: 5.5vw;
  }
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 3em;
    line-height: 1em;
  }
}

@media screen and (max-width: 408.16px) {
  .title {
    font-size: 34px;
    line-height: 31px;
  }
}

.subTitle {
  font-size: 3.13vw;
  line-height: 3.13vw;
  font-weight: 400;
  text-align: center;
  color: white;
  padding: 20px 0 60px 0;
}

@media screen and (max-width: 800px) {
  .subTitle {
    font-size: 1.13vw;
    line-height: 1.13vw;
  }
}

@media screen and (max-width: 600px) {
  .subTitle {
    font-size: 6.13vw;
    line-height: 6.5vw;
  }
}

@media screen and (max-height: 760px) {
  .title {
    font-size: 5vh;
    line-height: 1em;
  }

  .subTitle {
    font-size: 4vh;
    line-height: 1em;
  }
}

.buttons {
  display: flex;
  gap: 15px;
  margin-top: -26px;
}

.modal {
  padding: 0 !important;
}

.modal a {
  color: var(--accent);
}

@media screen and (max-width: 1200px) {
  .buttons {
    flex-direction: column;
    align-items: center;
    width: 90vw;
    gap: 5px;
  }
  .buttons button {
    width: 90%;
  }
}
