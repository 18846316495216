.wrapper{
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 3.13vw;
  padding-bottom: 100px;
  z-index: 1;
}
@media screen and (max-width: 638.98px){
  .wrapper{
    padding: 0 20px;
  }
}
.letters{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 250px;
  margin-bottom: 150px;
  position: relative;
  width: 100%;
  z-index: 1;
}
.img4, .img5, .img6{
  position: absolute;
  z-index: -9999;
  transform: translateX(-50%) translateY(-50%);
}
.img4 {
  transform: translateY(-10%);
}
.img6{
  transform: none;
  height: 47vw;
  top: -80%;
  right: -60px;
}
.img5{
  top:50%;
  left: 35%;
  height: 250px;
}
.img4{
  height: 15.49vw;
  z-index: -1;
  left: 66%;
  top: 1.5vw;
}

@media screen and (max-width: 1200px) {
  .letters{
    margin-top: 125px;
    margin-bottom: 75px;
  }
  .img5{
    height: 200px;
  }
  .img6{
    height: 500px;
  }
}
@media screen and (max-width: 900px) {
  .letters{
    margin-top: 75;
    margin-bottom: 25px;
  }
  .img5{
    height: 175px;
  }
  .img6{
    height: 300px;
  }
}

@media screen and (max-width: 800px) {
  .letters{
    margin-top: 100px;
    margin-bottom: 0;
  }
  .img5{
    height: 150px;
  }
}

.exceptionalText{
  font-size: 3.96vw;
  line-height: 3.96vw;
  text-align: center;
  width: 70%;
  margin-top: 90px;
  margin-bottom: 150px;
  text-transform: uppercase;
}
@media screen and (max-width: 606.06px){
  .exceptionalText{
    font-size: 24px;
    line-height: 24px;  
  }
}
