.materialsVideo {
  padding: 55px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--white, #FFF);
}
@media screen and (max-width: 600px) {
  .materialsVideo {
    padding: 35px 30px;
    margin-bottom: 60px;
  }
}

.materialsVideo iframe {
  border-radius: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
