.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 3.13vw;
  width: calc(100% + (3.13vw * 2));
  padding: 3.13vw;
  height: fit-content;
  min-height: 277px;
  background-color: var(--accent);
  font-size: 5.78vw;
  line-height: 5.78vw;
  margin-top: 100px;
  margin-left: -3.13vw;
  z-index: 0;
}

@media screen and (max-width: 638.98px) {
  .wrapper {
    margin-left: -20px;
    width: calc(100% + (20px * 2));
  }
}
@media screen and (max-width: 958.47px) {
  .wrapper {
    font-size: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 479.23px) {
  .wrapper {
    border-radius: 15px;
  }
}

@media screen and (max-width: 415.22px) {
  .wrapper {
    font-size: 24px;
    line-height: 24px;
  }
}
