table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

tbody, thead{
  text-align: left;
}

tbody th {
  border: solid 1px var(--grey-3, #EBEFF4);
  border-style: solid none solid none;
  padding: 15px;
  background-color: var(--grey-5, #FAFAFB);
}

tbody tr th:first-child { 
  border-radius: 10px 0 0 10px; 
  border-style: solid none solid solid;
}
tbody tr th:last-child { 
  border-radius: 0 10px 10px 0; 
  border-style: solid solid solid none;
  color: var(--grey-1, #8F939C);
  text-align: right;
}
thead tr th {
  color: #8F939C;
  padding: 0 15px 0 15px;
}
thead tr th:last-child {
  text-align: right;
}

.icon{
  height: 20px;
  width: 20px;
  margin-bottom: -4px;
  margin-right: 5px;
  filter: invert(51%) sepia(49%) saturate(255%) hue-rotate(96deg) brightness(97%) contrast(89%);
}

.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  border-radius: 15px;
}
.item{
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
  border-radius: 5px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--grey-5, #FAFAFB);
}
.column{
  display: flex;
  flex-direction: row;
}
.key, .value{
  width: 50%;
}
.column .key{
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  margin: 0;
}
.column .value{
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  margin: 0;
  word-break: break-all;
}
.item .column:first-child .value,
.item .column:last-child .value{
  color: var(--grey-1, #8F939C);
}

.line{
  height: 0;
  width: 100%;
  border-top: 1px solid var(--grey-3, #EBEFF4);
}
