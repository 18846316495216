.corp {
  margin-top: 13.7vw;
  margin-bottom: 14vw;
  padding: 0 3.13vw;
  position: relative;
  z-index: 0;
}

@media screen and (max-width: 638.98px) {
  .corp {
    padding: 0 20px;
  }
}
@media screen and (max-width: 704.54px) {
  .corp {
    margin-top: 103.99px;
  }
}
@media screen and (max-width: 685.87px) {
  .corp {
    margin-bottom: 100px;
  }
}

.title {
  font-size: 9.48vw;
  line-height: 7.9vw;
  letter-spacing: -3px;
  font-family: Neue Machina;
  font-weight: 400;
  z-index: 1;
  margin: 0;
  text-transform: uppercase;
  padding: 0 5px;
}
.title.center {
  text-align: right;
}
.countriesCount {
  font-size: 9.65vw;
  line-height: 9.31vw;
}

.title {
  text-align: left;
}

.title:nth-child(2n) {
  text-align: right;
}

.corpInfo {
  display: flex;
  justify-content: space-between;
}

.figureContainer {
  display: flex;
  justify-content: center;
  width: 50%;
}

.headings {
  margin-bottom: 8.66vw;
  position: relative;
}
@media screen and (max-width: 540.22px) {
  .headings {
    margin-bottom: 45px;
  }
}
.img7 {
  position: absolute;
  transform: rotate(-10deg);
  left: 49.5%;
  top: -10%;
  width: 21vw;
  z-index: -1;
}

.figure {
  width: 26.15vw;
  height: 26.15vw;
  background: var(--accent);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Neue Machina;
  font-size: 185.318px;
  font-style: normal;
  font-weight: 700;
  transform: rotate(-12.371deg);

  margin-top: 4px;
  margin-left: 20px;
}

@media screen and (max-width: 1200px) {
  .figure {
    margin-left: 0;
  }
}

.countriesCount {
  display: flex;
  flex-direction: column;
  position: relative;
  letter-spacing: -3px;
  margin-top: -50px;
  margin-left: -5px;
}

.countries {
  position: absolute;
  top: 90%;
  left: 75%;
  margin-left: auto;
  font-family: Manrope;
  font-size: 1.25vw;
  line-height: 1.25vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  letter-spacing: -1px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 65px;
  width: 50%;
  margin-left: 27px;
}

.bigText {
  width: 75%;
  color: var(--dark);
  font-family: Neue Machina;
  font-size: 2.17vw;
  line-height: 2.39vw;
  letter-spacing: 0px;
  font-style: normal;
  font-weight: 500;
  margin-top: 32px;
}

.smallText {
  width: 35%;
  color: var(--grey-1);
  font-family: Manrope;
  font-size: 1.46vw;
  line-height: 1.6vw;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
}
.boldAccentText {
  color: var(--accent);
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  .corpInfo {
    flex-direction: column-reverse;
    align-items: center;
  }
  .figureContainer {
    display: block;
    width: auto;
  }
  .text {
    width: 100%;
  }
  .bigText {
    width: 100%;
    font-size: 22px;
    line-height: 24px;
    margin-top: 0;
  }
  .smallText {
    width: 100%;
    font-size: 18px;
    line-height: 19.8px;
    margin-top: 0px;
  }
  .figure {
    margin-top: 100px;
  }
  .corp {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 379.75px) {
  .title {
    font-size: 36px;
    line-height: 36px;
  }
}
@media screen and (max-width: 1623.52px) {
  .countriesCount {
    font-size: 156.67px;
    line-height: 151.19px;
  }
}
@media screen and (max-width: 1600px) {
  .countries {
    font-size: 20px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1621.41px) {
  .figure {
    height: 424px;
    width: 424px;
  }
}
@media screen and (max-width: 1013.82px) {
  .bigText {
    font-size: 22px;
    line-height: 24.2px;
  }
}
@media screen and (max-width: 1232.88px) {
  .smallText {
    font-size: 18px;
    line-height: 19.8px;
  }
}
