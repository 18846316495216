.wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 55px;
  border-radius: 15px;

  background-color: white;
}
.subwrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 35px;
}
.sponsor{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
}
.icon{
  padding: 20px;
  border-radius: 10px;
  background-color: #F4F5F7;
}
.info{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.name{
  color: #8F939C;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.16px;
  margin: 0;
}
.value{
  color: var(--accent);
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 32px */
  letter-spacing: -1.6px;
  margin: 0;
}
.buttonIcon{
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

@media screen and (max-width: 1300px) {
  .subwrapper{
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  .wrapper{
    padding: 55px 30px;
  }
}