.menuItem {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 2px;
  gap: 5px;
  font-weight: 500;
  user-select: none;
}

@media screen and (max-width: 805px) {
  .iconWrapper,
  .iconWrapper svg {
    width: 30px !important;
    height: 30px !important;
  }
}

.content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.content:hover{
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(50%) contrast(104%);
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  color: var(--grey-1);
  transition: 0.3s ease-in-out;
}

.active .text {
  color: var(--dark);
  transition: 0.3s ease-in-out;
}

.active .iconWrapper svg {
  /* filter: invert(6%) sepia(13%) saturate(3281%) hue-rotate(108deg) brightness(95%) contrast(104%); */
  filter: brightness(0%);
  transition: 0.3s ease-in-out;
}

.iconWrapper,
.iconWrapper svg {
  width: 20px;
  height: 20px;
  transition: 0.3s ease-in-out;
}
