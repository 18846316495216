.wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}
/* .wrapper .card:nth-child(1){ */
/*   background-color: #87C7AB; */
/*   color: white; */
/* } */
/* .wrapper .card:nth-child(1) .coin{ */
/*   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(96deg) brightness(1030%) contrast(101%); */
/* } */
/* .wrapper .card:nth-child(1) .button{ */
/*   color: black; */
/* } */

.wrapper .active {
  /* background-color: #87C7AB; */
  color: white;
}
.wrapper .active .coin{
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(96deg) brightness(1030%) contrast(101%);
}
.wrapper .active .button{
  color: black;
}
.card{
  background-color: #F4F5F7;
  max-width: 220px;
  flex: 1 0 190px;
  border-radius: 15px;
  font-weight: 600;
  color: #8F939C;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 400ms ease-in-out;
}
.title{
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  padding: 20px 0;
  color: white;
  border-radius: 15px 15px 0 0;
  position: relative;
  justify-content: center;
}

.title .expandIcon {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
  transition: 300ms ease-in-out;
}

.compactTitle {
  display: flex;
  align-items: center;
}

.title > p{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.canna > p {
  gap: 10px;
  transition: 0.3s ease-in-out;
  margin-right: 0;
}

@media screen and (max-width: 500px) {
  .compactTitle {
    justify-content: space-between;
    padding-right: 50px;
  }

  .title > p {
    gap: 0;
    transition: 0.3s ease-in-out;
    /* margin-right: -16px; */
    padding-left: 16px;
  }

  .infoIcon {
    display: none;
  }
}

@media screen and (min-width: 500px) {
  .title .expandIcon {
      display: none;
  }
}

/* .title > p img { */
/*   opacity: 0; */
/*   transition: 0.3s ease-in-out; */
/* } */

.title:hover > p img {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease-in-out;
}
.name{
  margin: 0;
  font-weight: 500;
}
.value{
  margin: 0;
  color: black;
  line-height: 16px;
  font-weight: 700;
  height: 16px;
}
.cost{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 20px 0;
  margin-bottom: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.26);
  border-bottom: 1px solid rgba(255, 255, 255, 0.26);
  transition: 400ms ease-in-out;
}
.dailyInterest{
  margin-bottom: 15px;
}
.days{
  margin-bottom: 15px;
  transition: 400ms ease-in-out;
}
.logo{
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  filter: invert(71%) sepia(10%) saturate(265%) hue-rotate(184deg) brightness(82%) contrast(82%);
  transition: 400ms ease-in-out;
}
.directBonus{
  margin-bottom: 15px;
  transition: 400ms ease-in-out;
}
.directBonus .value{
  text-align: center;
}
.binaryBonus{
  margin-bottom: 15px;
  transition: 400ms ease-in-out;
}
.button{
  border-top: 1px solid rgba(255, 255, 255, 0.26);
  width: 100%;
  color: #4FA778;
  border-radius: 0 0 15px 15px;
  font-size: 16px;
  font-weight: 600;
}

.cardCollapsed {
  transition: 400ms ease-in-out;
}

.cardCollapsed .dailyInterest {
  margin-top: 15px;
  margin-bottom: 0;
  transition: 400ms ease-in-out;
}

.cardCollapsed .cost,
.cardCollapsed .days,
.cardCollapsed .logo,
.cardCollapsed .directBonus,
.cardCollapsed .binaryBonus,
.cardCollapsed .dailyInterest,
.cardCollapsed .button {
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  transition: 400ms ease-in-out;
}

.cardCollapsed .title img {
  transform: translate(0, -50%) rotate(0);
  transition: 300ms ease-in-out;
}

.dailyMob {
  display: none;
}

.cardCollapsed .dailyMob {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 16px;
}

.card:hover .tip {
  transition: 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

.bold {
  font-weight: 700;
}

.tip {
  position: absolute;
  top: 0;
  left: 40%;
  transform: translateY(-125%);
  color: var(--dark);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  padding: 20px;
  border: 1px solid black;
  width: 340px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  background: #fff;
}

.tip .triangle {
  content: "";
  position:absolute;
  margin-top:-6px;
  margin-left:-5px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid black;
  transform: rotate(180deg) translateY(-100%);
  bottom: 0;
  left: 40px;
}

@media screen and (max-width: 500px) {
  .card{
    max-width: none;
    flex: 1 0 100%;
    width: 100%;
  }
}
