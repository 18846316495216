.footer {
  background: var(--color-gray-200);
  padding: 0 3.13vw;
  position: relative;
}
@media screen and (max-width: 958.47px){
  .footer {
    font-size: 30px;
  }
}

.top {
  padding: 120px 0;
  justify-content: space-between;
}

@media screen and (max-width: 1500px) {
  .top {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}

.left, .right, .top {
  display: flex;
}

.column {
  display: block;
  width: 100%;
}

.column span {
  color: var(--White, #FFF);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
  cursor: pointer;
}

.left {
  gap: 120px;
}

.right {
  gap: 5px;
  align-items: center;
}

.buttonToStart{
  border: 0;
  outline: 0;
  background-color: rgba(0,0,0,0);
  cursor: pointer;
}

.contact {
  color: var(--color-silver);
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 30px 50px;
  border-radius: 300px;
  border: 1px solid #343434;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 38px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.secondary {
  display: flex;
  align-items: center;
  color: var(--color-gray-100);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email {
  display: flex;
  align-items: center;
  color: var(--accent);
  font-family: Neue Machina;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.44px;
}

.bottomLeft {
  display: flex;
  gap: 100px;
  margin-right: 25px;
}

.bottomCenter {
  display: flex;
  gap: 140px;
}

.bottomCenter span {
  cursor: pointer;
}

.center{
  display: none;
}
.column{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.column a{
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
}

@media screen and (max-width: 1200px) {
  .left{
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .column:nth-child(1){
    position: absolute;
    top: 10%;
  }
  .right{
    flex-wrap: wrap;
    justify-content: center;
  }
  .email{
    display: none;
  }
  .bottom{
    display: flex;
    flex-direction: column;
    align-items: flex-start
  }
  .bottomLeft{
    display: flex;
    flex-direction: column;
  }
  .bottomCenter{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 10px;
  }
  .bottomRight{
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
  .center{
    display: flex;
    justify-content: center;
    padding: 20px 0 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }
  .center .email{
    display: block;
    font-size: 28.96px;
    line-height: 27.94px;
    font-weight: 300;
  }
}

@media screen and (max-width: 1200px){
  .column:nth-child(1){
    position: absolute;
    top: 6%;
  }
  .left {
    gap: 0;
  }
  .contact{
    font-size: 16px;
    padding: 20px 30px;
  }
}
