.burger {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 16px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.open {
  gap: 0;
  transition: 0.5s ease-in-out;
}

.open div:nth-child(2) {
  opacity: 0;
  margin-bottom: -4px;
  transition: 0.3s ease-in-out;
}

.open div:nth-child(1) {
  transform: rotate(45deg);
  transition: 0.5s ease-in-out;
}

.open div:nth-child(3) {
  transform: rotate(-45deg);
  transition: 0.5s ease-in-out;
}

.burger div {
  width: 24px;
  height: 2px;
  background: var(--dark);
  transition: 0.5s ease-in-out;
}
