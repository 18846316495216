.product {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: #FFF;
  padding: 55px;
}

.imageContainer {
  margin-bottom: 20px;
}

.imageContainer img {
  max-width: 530px;
  width: 100%;
}

.dosage, .dosage div {
  display: flex;
  align-items: center;
}

.dosage {
  gap: 45px;
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: 60px;
}

.dosage div {
  gap: 10px;
}

.price {
  color: #000;
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.48px;
}

.addCart {
  border-radius: 10px !important;
}

.out {
  background: #8F939C;
}

.soon {
  background: #DEECE4;
  color: var(--dark);
}

.soon svg, .out svg {
  display: none;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  display: flex;
  align-items: center;
  gap: 20px;
}
.actions button{
  min-width: 156px;
}

.head {
  margin-bottom: 40px;
}

.title {
  color: var(--accent, #4FA778);
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1.6px;
  margin-bottom: 5px;
}

.subtitle {
  color: #000;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.2px;
}
@media screen and (max-width: 1150px) {
  .imageContainer{
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .product{
    padding: 35px 30px;
  }
  .title{
    font-size: 22px;
  }
  .subtitle{
    font-size: 16px;
  }
  .dosage{
    font-size: 14px;
    gap: 35px;
  }
  .price{
    font-size: 36px;
    width: 100%;
    text-align: left;
  }
  .bottom{
    flex-direction: column;
  }
  .actions{
    width: 100%;
    justify-content: space-between;
  }
}
