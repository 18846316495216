.title{
  text-align: center;
  font-family: Manrope;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%;
  letter-spacing: -1.44px;
}
.subtitle{
  color: var(--grey-1, #8F939C);
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  letter-spacing: -0.24px;
}