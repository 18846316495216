.container {
  position: relative;
}

.badge {
  position: absolute;
  top: -12px;
  right: -1px;
  background: #F46E86;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  z-index: 999;
  color: #FFF;
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.56px;
}

.big {
  width: 15px;
  height: 15px;
  top: -3px;
  right: -6px;
  font-size: 10px;
  line-height: 15px;
}
