.wrapper{
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 3.13vw;
  padding-bottom: 100px;
  z-index: 1;
}
@media screen and (max-width: 638.98px){
  .wrapper{
    padding: 0 20px;
  }
}
.tokensCards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%; 
  width: calc(100% + 35px);
  gap: 20px;
  margin-top: 10px;
  margin-left: -15px;
  margin-bottom: 5px;
  z-index: 1;
  overflow: hidden;
}
.titleWrapper, 
.ourPartnersTitle,
.projectTeamTitle,
.ourLegalTitle{
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  margin-top: 100px;
}
.ourPartnersTitle{
  margin-bottom: 5.21vw;
}
.ourPartnersTitle .title:nth-child(2){
  text-align: left;
  margin-left: 9.90vw;
}
.projectTeamTitle .title:nth-child(2){
  text-align: left;
  margin-left: 17.90vw;
}
.ourLegalTitle .title:nth-child(2){
  text-align: left;
  margin-left: 10.6vw;
}

.title{
  font-size: 9.48vw;
  line-height: 7.48vw;
  text-transform: uppercase;
  letter-spacing: -3px;
  font-weight: 400;
  font-family: Neue Machina;
  margin: 0;
}

@media screen and (max-width: 379.75px){
  .title{
    font-size: 36px;
    line-height: 36px;
  }
}

.tokenText{
  position: relative;
  text-align: center;
  font-size: 5.89vw;  
  line-height: 5.73vw;
  font-weight: 700;
  font-family: 'Neue Machina';
  text-transform: uppercase;
  letter-spacing: -1px;
  padding: 0 7%;
  margin-left: -10px;
  margin-top: 7.03vw;
  margin-bottom: 12.50vw;
  @media screen  and (max-width: 407.47px) {
    font-size: 24px;
    line-height: 24px; 
  }
  @media screen and (max-width: 480.00px){
    margin-bottom: 60px;
  }
  @media screen and (max-width: 640.11px){
    margin-top: 45px;
  }
}
.tokenImg{
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 38vw;
}
.tokenCardsImg{
  position: absolute;
  height: 100%;
  z-index: 0;
}
.downloadText{
  position: relative;
}
@media screen and (max-width: 1200px) {
  .tokenText{
    padding: 0;
  }
  .tokensCards{
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    width: 100%;
  }
}
@media screen and (min-width: 1201px) {
  .tokensCards > div:nth-child(3){
    padding-top: 110px;
    padding-left: 140px;
  }
  .tokensCards > div:nth-child(4){
    padding-top: 110px;
    padding-left: 140px;
  }
}
