.ourProducts {
  background: var(--accent) url(/src/assets/images/glassy-3.png) no-repeat center;
  border-radius: 3.13vw;
  position: relative;
  padding-bottom: 51px;
  display: flex;
  justify-content: center;
}

.ourProductsContainer {
  padding: 9vw 3.13vw 4.8vw 3.13vw;
  width: 100%;
}
.title {
  font-size: 9.48vw;
  line-height: 7.48vw;
  text-transform: uppercase;
  font-family: Neue Machina;
  font-weight: 400;
  margin: 0;
  letter-spacing: -3px;
}

@media screen and (max-width: 379.75px) {
  .title {
    font-size: 36px;
    line-height: 36px;
  }
}

.title:nth-child(2) {
  color: white;
  margin-left: 13.59vw;
}

@media screen and (max-width: 388.67px) {
  .title:nth-child(2) {
    margin-left: 52.82px;
  }
}

.headings {
  display: flex;
  flex-direction: column;
  margin-bottom: 105px;
}

.productsHeading {
  text-align: left;
}

.productsHeading:nth-child(2) {
  margin-left: 230px;
  color: white;
}

.cardsContainer {
  gap: 20px;
  margin-top: 150px;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
}

.slides {
  width: 60%;
  position: relative;
}
.slides::after {
  position: absolute;
  content: "";
  height: calc(100% + 160px);
  background-color: #4fa778;
  width: 120px;
  top: -160px;
  z-index: 1;
  right: 0;
  left: calc(100% + 10px);
}

.cards {
  display: none;
}

.buttons {
  display: flex;
  align-items: flex-end;
  padding-bottom: 105px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  border: 1px solid #fff;
}

.button svg {
  filter: invert(100%);
}

.lastItemButton {
  background: #fff;
  border: none;
}

.lastItemButton svg {
  filter: none;
}

.button:nth-child(1) {
  transform: rotate(180deg);
}

.button:nth-child(2) {
  margin-left: 10px;
}

.head {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .slides {
    display: none;
  }
  .cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .cardsContainer {
    display: flex;
    flex-direction: column;
  }

  .buttons {
    display: none;
  }
}
