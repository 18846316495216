.info {
  position: relative;
  margin-top: 210px;
  padding: 0 55px;
  @media screen and (max-width: 800px) {
    padding: 0 14px;
    margin-top: 50px;
  }
}
.titleWrapper {
  margin-bottom: 185px;
  @media screen and (max-width: 800px) {
    margin-bottom: 0px;
  }
}
.title {
  font-size: 7.48vw;
  line-height: 6vw;
  font-weight: 400;
  font-family: Neue Machina;
  letter-spacing: -3px;

  @media screen and (max-width: 481.28px) {
    font-size: 36px;
    line-height: 36px;
  }
}
.title span {
  margin-left: 11%;
}
.cardList {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: 271.75px;
}

@media screen and (max-width: 1200px) {
  .cardList {
    flex-direction: column;
    align-items: center;
    gap: 110px;
  }
}
.cardList div:nth-child(1) img {
  top: 60%;
  left: 50%;
}
.cardList div:nth-child(2) img {
  width: 30vw;
  left: 57%;
}
.cardList div:nth-child(3) img {
  width: 30vw;
  left: 57%;
}
.cardList div:nth-child(4) img {
  width: 28vw;
  left: 53%;
  top: 65%;
}
