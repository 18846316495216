.row {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.item {
  position: relative;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.item::before, .item::after {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  z-index: -10;
}

.item::before {
  transform: scale(1, -1);
  right: 100%;
}

.item::after {
  left: 99%;
}

.badge {
  width: 25px;
  height: 25px;
  line-height: 25px;
  background: var(--accent);
  color: white;
  border-radius: 50%;
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.56px;
  position: absolute;
  right: 0; top: 0;
  transform: translate(35%, -50%);
}

.login {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  height: 22px;
}

.lvl1::before {
  right: 60%;
  top: 20% !important;
}

.lvl1::after {
  left: 60%;
  top: 20% !important;
}

.lvl1::before, .lvl1::after {
  width: 278px;
  height: 148px;
  top: 50%;
  background: url(/src/assets/images/scheme/lvl-1.svg) no-repeat;
}

.lvl2::before, .lvl2::after {
  width: 97px;
  height: 140px;
  background: url(/src/assets/images/scheme/lvl-2.svg) no-repeat;
}

.lvl3::before, .lvl3::after {
  width: 15.6px;
  height: 132.5px;
  background: url(/src/assets/images/scheme/lvl-3.svg) no-repeat;
}

.item .icon {
  width: 120px;
  height: 120px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--grey-4, #F4F5F7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.item .icon svg {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.lvl2 .icon {
  width: 110px;
  height: 110px;
}

.lvl3 .icon {
  width: 100px;
  height: 100px;
}

.lvl3 .icon svg {
  width: 40px;
  height: 40px;
}

.lvl4 .icon {
  width: 80px;
  height: 80px;
}

.lvl4 .icon svg {
  width: 30px;
  height: 30px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 55px;
}

@media screen and (max-width: 1620px) {
  .row {
    gap: 20%;
  }
}

@media screen and (max-width: 1330px) {
  .row {
    gap: 10px;
  }

  .lvl1::before, .lvl1::after {
    top: 30%;
  }

  .lvl1::before {
    right: 20%;
  }

  .lvl1::after {
    left: 20%;
  }

  .lvl2::before {
    right: 80%;
  }

  .lvl2::after {
    left: 80%;
  }
}

@media screen and (max-width: 1150px) {
  .container {
    overflow-x: auto;
    display: flex;
  }
}
