.transfer {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

@media screen and (max-width: 1800px) {
  .transfer {
    gap: 40px;
    flex-wrap: wrap;
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}
@media screen and (max-width: 600px) {
  .box {
    flex: 1 0 100%;
  }
}
.box h2{
  width: 100%;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .box h2{
    font-size: 24px;
  }
}

.card {
  padding: 55px 0px;
  border-radius: 15px;
  width: 100%;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--white, #FFF);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  .card {
    min-width: 0px;
    max-width: 10000px;
    width: 100%;
  }
}

.head {
  padding: 0 54px 35px 54px;
  border-bottom: 1px solid var(--grey-3, #EBEFF4);
}
@media screen and (max-width: 600px) {
  .head {
    padding: 0 30px 35px 30px
  }
}
.QRbutton{
  padding: 16px 10px;
  letter-spacing: -0.24px;
  gap: 5px;
}
.body {
  padding: 35px 54px 0 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 360px;
}
@media screen and (max-width: 700px) {
  .bottom{
    width: 275px;
  }
  .bottom .actions p{
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .body {
    padding: 35px 30px
  }
}

.rate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  padding: 20px 0;
  margin-bottom: 35px;
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.deposit .inputs {
  margin-bottom: 75px;
}

.qrcode {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
}
.actions button {
  width: 100%;
}
.actions p{
  margin: 0;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .actions p{
    font-size: 12px;
  }
}

.balance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .balance {
    flex-direction: column;
    gap: 25px;
  }
}

.balance .label {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: 5px;
}

.balance .value {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: Manrope;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -1.3px;
}

.balance .value img {
  width: 27px;
  height: 27px;
}

.auth {
  margin-top: 10px;
}

.auth a {
  color: var(--accent, #4FA778);
}

.authMessage {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.32px;
}

@media screen and (max-width: 600px) {
  .authMessage {
    font-size: 14px;
  }
}

.authTimeWarning {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}

.withdraw .body {
  display: flex;
  flex-direction: column;
}

.withdraw .actions {
  flex: 1 0 50%;
}

.auth{
  margin-bottom: 30px;
}
