.dashboardIncome {
  padding: 35px 55px;
  border-radius: 15px;
  background: white;
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;
}

.suptitle {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
  padding-bottom: 5px;
}

.title {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -2.4px;
  padding-bottom: 5px;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  align-items: center;
}

.incomeInput, .incomeInput:focus  {
  border: none;
  padding: 0;
  background: transparent;
  width: 5ch;
}

.incomeInput::-webkit-outer-spin-button,
.incomeInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.incomeInput {
  -moz-appearance: textfield;
}

.iconButton {
  padding: 20px;
}

.buttonGroup {
  margin: 65px 0 70px 0;
}

.calcBlock {
  display: flex;  
  justify-content: space-between;
  margin: 50px;
  gap: 20px;
}

@media screen and (max-width: 1200px) {
  .calcBlock {
    margin: 50px 0;
  }
}
@media screen and (max-width: 800px) {
  .calcBlock {
    flex-direction: column;
  }
}

.calcTitle {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: 5px;
}

.calcValue {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1.6px;
}

@media screen and (max-width: 1400px) {
  .calcValue {
    font-size: 22px;
  }
}

@media screen and (max-width: 500px) {
  .dashboardIncome {
    padding: 35px 20px;
  }
  .buttonGroup {
    margin-bottom: 0;
    margin-top: 26px;
  }

  .calcBlock {
    margin-top: 25px;
  }
}
