.button {
  border: none;
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  --button-accent: var(--accent);
}

.label, .button svg {
  z-index: 500;
  transition: opacity .45s cubic-bezier(0.215, 0.61, 0.355, 1),border-radius 0s .45s,transform 0s .45s;
}

.label::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 100%;
  opacity: 0;
  transform: translate(0, 100%);
  transition: opacity .45s cubic-bezier(0.215, 0.61, 0.355, 1),border-radius 0s .45s,transform 0s .45s;
}

.button:hover .label::after {
  opacity: 1;
  border-radius: 0;
  transform: translate(0);
  transition: border-radius .45s cubic-bezier(0.215, 0.61, 0.355, 1),transform .45s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.button:hover {
  color: var(--dark);
  transition: color .45s cubic-bezier(0.215, 0.61, 0.355, 1),transform .45s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* variant */

.primary {
  background: white;
  color: var(--dark);
}

.primary .label::after {
  background: var(--dark);
}

.primary:hover {
  transition: background .45s cubic-bezier(0.215, 0.61, 0.355, 1),transform .45s cubic-bezier(0.215, 0.61, 0.355, 1);
  color: white;
}

.accent {
  background: var(--accent);
  color: white;
}

.outline {
  border: 1px solid var(--grey-3);
  color: white;
}

.outline:hover svg {
  filter: invert(100%);
  transition: opacity .45s cubic-bezier(0.215, 0.61, 0.355, 1),border-radius 0s .45s,transform 0s .45s;
}

.outline-filled {
  border: 1px solid var(--grey-3);
  background: var(--grey-4, #F4F5F7);
  color: var(--dark);
}

.outline-dark {
  border: 1px solid var(--grey-3);
  color: var(--dark);
}

.outline-dark:hover {
  color: white;
}

.outline-dark .label::after {
  background: var(--dark);
}

.active {
  color: white;
}

.active .label::after {
  background: var(--button-accent);
  opacity: 1;
  transform: translate(0);
  border-radius: 0;
}

.disabled {
  opacity: 0.6;
}

.disableEffects {
  cursor: default;
}

.disableEffects .label::before,
.disableEffects .label::after {
  all: unset !important;
}

.label{
  font-size: 0.94vw;  
  line-height: 1.28vw;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  position: relative;
  cursor: unset;
}

.loading:hover {
  color: white !important;
}

.loading::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 599;
}

.loading::before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  background: transparent;
  z-index: 699;
  border: 3px solid var(--color-gray-400);
  border-right: 3px solid white;
  border-radius: 50%;
  animation: rotation 1s linear 0s infinite;
}

.loading:hover .label::after {
  all: unset !important;
}

@media screen and (max-width: 1702.13px){
  .label{
    font-size: 16px;
    line-height: 22px;  
  }
}

/* size */

.medium {
  padding: 16px 25px;
}

.big {
  padding: 20px 25px;
  border-radius: 10px;
}
