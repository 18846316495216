.binaryStructure {
  padding: 55px 45px;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--white, #FFF);
}

.head {}

.searchBar {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 40px;
}

.searchBar div {
  flex: 1 0 54.3%;
}

.searchBar button {
  padding-top: 18px;
  padding-bottom: 18px;
  flex: 1 0 auto;
}

.legend {
  display: flex;
  justify-content: space-between;
}

.legend .item {
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
}

.imgWrapper {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--grey-4, #F4F5F7);
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.controls .top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
}

.controls .top .text {
  color: #4A494E;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
}

.controls .top .gridItem {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.controls .top .wrapper {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid var(--accent, #4FA778);
  background: var(--grey-5, #FAFAFB);
  position: absolute;
  width: 350px;
  left: 100%;
}

.controls .bottom {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 80px;
}

.controls .bottom button:nth-child(2) {
  flex: 0 1 365px;
}

.iconButton {
  min-height: 60px;
  min-width: 60px;
  padding: 20px;
}
.icon{
  height: 20px;
  width: 20px;
}

.structure {
  display: flex;
  margin-top: 60px;
  gap: 30px;
  flex-direction: column;
}

.subStructure {
  display: flex;
  margin-left: 50px;
  gap: 30px;
  flex-direction: column;
}

.structure .line {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.structure .item {
  display: flex;
  gap: 15px;
  font-size: 18px;
  align-items: center;
}

.structure .item .arrowIcon{
  cursor: pointer;
  transition: 0.5s;
  transform: rotate(-90deg);
}

.structure .item .arrowIconRotated{
  transform: rotate(0deg);
}

.structure .badge {
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: var(--accent);
  color: white;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(35%, -50%);
}

.structure .partner {
  display: flex;
  position: relative;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--grey-4, #F4F5F7);
}

.structure .partner .icon {
  width: 25px;
  height: 25px;
}



@media screen and (max-width: 1100px) {
  .binaryStructure{
    padding: 35px 30px;
  }
  .searchBar{
    flex-direction: column;
    justify-content: center;
    align-items: stretch
  }
  .legend{
    flex-direction: column;
    gap: 10px;
  }
  .controls .top .gridItem .wrapper {
    display: none;
  }
  .controls .top {
    grid-template-columns: 0fr 1fr 0fr;
  }
  .controls .top .text {
    font-size: 14px;
  }
  .legend .item {
    font-size: 14px;
  }
  .imgWrapper{
    padding: 20px 15px;
  }
}
