.info{
  margin-top: 210px;
  padding: 0 60px;
}
.titleWrapper{
  position: relative;
}
.title{
  font-size: 4.48vw;  
  line-height: 4.48vw;
  font-weight: 500px;
  font-family: Neue Machina;
}

.abstract1 {
  position: absolute;
  right: 10px;
}

.abstract2 {
  position: absolute;
  bottom: -60px;
  left: 70px;
  transform: scale(-1, 1);
}

.cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 120px;
}
@media screen and (max-width: 1500px) {
  .cards{
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
  .cards > div:nth-child(1n){
    /*margin: 0 auto 0 0;*/
  }
  .cards > div:nth-child(2n){
    /*margin: 0 0 0 auto;*/
  }
  .abstract1 {
    position: absolute;
    right: 10px;
    bottom: 300px;
  }
}
@media screen and (max-width: 800px) {
  .cards{
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
  .cards > div:nth-child(1n){
    margin: 0;
  }
  .cards > div:nth-child(2n){
    margin: 0;
  }
}
@media screen and (max-width: 535.71px){
  .title{
    font-size: 24px;
    line-height: 24px;  
  }
}
