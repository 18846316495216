.card {
  padding: 60px 31px 45px 31px;
  border-radius: 15px;
  border: 1px solid #92c9ab;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 133px;
  transition: 0.25s;
  height: 416px;
  user-select: none;
}

.head,
.body {
  display: flex;
}

.body {
  gap: 40px;
  justify-content: space-between;
}

.title {
  color: white;
  font-family: Neue Machina;
  font-size: 3.18vw;
  line-height: 3.18vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -3.66px;
  margin-top: -7px;
}

.subtitle {
  color: white;
  font-family: Neue Machina;
  font-size: 1.04vw;
  line-height: 1.04vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  margin-top: 2px;
  text-transform: uppercase;
  width: 80%;
}

.bodyLeft {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
}

.image {
  position: absolute;
  right: -60px;
  top: -140px;
  z-index: -1;
}

.button {
  margin-top: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.description {
  color: var(--dark);
  font-family: Manrope;
  font-size: 16px;
  line-height: 16.8px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.16px;
  width: 70%;
}

@media screen and (max-width: 1200px) {
  .card {
    margin-bottom: 100px;
    min-width: 0px;
  }
  .body {
    flex-direction: column;
    gap: 25px;
  }
}
@media screen and (max-width: 600px) {
  .card {
    height: auto;
  }
  .card:last-child {
    margin-bottom: 0;
  }
  .description {
    width: 100%;
  }
  .bodyLeft {
    width: 100%;
  }
  .image {
    position: absolute;
    right: -120px;
    top: -140px;
    z-index: -1;
  }
}

@media screen and (max-width: 1006.29px) {
  .title {
    font-size: 32px;
    line-height: 32px;
  }
}
@media screen and (max-width: 1538.46px) {
  .subtitle, .description {
    font-size: 14px;
    line-height: 14px;
  }
}
