.wrapper {
  background: #D4D8E1;
  position: relative;
  height: 100vh;
}

.inner {
  position: absolute;
  top: 35px;
  left: 35px;
  right: 35px;
  bottom: 35px;
  border-radius: 15px;
  background: var(--blocks-background, #F9F9F9);
  box-shadow: 0px 4px 100px 0px rgba(0, 2, 26, 0.12);
  display: flex;
  padding: 35px;
}

.left {
  background: var(--accent) url(/src/assets/images/glassy-6.png) no-repeat 100%;
  background-size: cover;
  width: 50%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left img {
  width: 100%;
  padding: 0 17%;
}

.right {
  width: 50%;
  padding: 0 158px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.mobileLogo {
  display: none;
}

@media screen and (max-width: 1500px) {
  .right {
    padding: 0 80px;
  }

  .inner {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .left {
    display: none;
  }

  .right {
    width: 100%;
    padding: 0;
  }

  .wrapper {
    background: var(--accent) url(/src/assets/images/glassy-6.png) no-repeat 100%;
    background-size: cover;
    height: 100%;
    padding: 35px 20px 20px 20px;
  }

  .inner {
    padding: 35px 30px;
    bottom: auto;
    position: unset;
  }

  .right {
    overflow: unset;
  }

  .mobileLogo {
    display: block;
    width: 100%;
    padding-bottom: 40px;
  }
}
