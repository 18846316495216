.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  border-radius: 5px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--grey-5, #FAFAFB);
  background-image: linear-gradient(var(--grey-3), var(--grey-3));
  background-size: 40% 100%;
  background-repeat: no-repeat;
}

.accent {
  background-image: linear-gradient(var(--accent), var(--accent));
}

.percent {
  color: var(--dark, #001007);
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.8px;
}

.label {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: 10px;
}

.minmax {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.8px;
}

.minmax div {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  gap: 3px;
}
