.wrapper {
  display: flex;
  opacity: 0;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events:none;
  z-index: 999;
  transition: 0.1s ease-in-out;
}

.modal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 300px;
  min-width: 300px;
  background-color: white;
  border-radius: 15px;
  padding: 85px 104px 55px 104px;
  transform: scale(0.7);
  transition: 0.1s ease-in-out;
}

@media screen and (max-width: 1200px) {
  .modal{
    min-width: 100px;
    min-height: 100px;
    padding: 70px 20px 20px 20px;
  }
}

.modal.products{
  padding: 55px;
}
.modal.video{
  min-height: 0;
  min-width: 0;
  background-color: rgba(0,0,0,0);
}

.active{
  opacity: 1;
  pointer-events:auto;
}

.active .modal {
  transform: scale(1);
}

.close{
  position: absolute;
  top: 39px;
  right: 50px;
  margin: 0;
  padding: 0;
}
.closeImage{
  width: 38px;
  height: 38px;
}

@media screen and (max-width: 1200px) {
  .close{
    top: 20px;
    right: 20px;
  }
  .modal.products{
    padding: 40px 20px;
  }
  .modal{
    
  }
}