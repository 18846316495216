.wrapper{
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 3.13vw;
  z-index: 1;

  @media screen and (max-width: 638.98px){
    padding: 0 20px;
  }
}

.letters{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 250px;
  margin-bottom: 150px;
  position: relative;
  width: 100%;
  z-index: 1;
}
.accentCards{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 5%;
  height: 100%;
  gap: 10px
}
.title{
  font-size: 3.9vw;  
  line-height: 4.3vw;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 43px;
  margin-bottom: 6.51vw;
  font-family: Neue Machina;
  text-transform: uppercase;
  @media screen and (max-width: 535.71px){
    font-size: 24px;
    line-height: 24px;  
  }
}
.img9{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 26%;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  .letters{
    margin-top: 125px;
    margin-bottom: 75px;
  }
  .accentCards{
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 900px) {
  .letters{
    margin-top: 75;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 800px) {
  .letters{
    margin-top: 100px;
    margin-bottom: 0;
  }
}

.countryImg{
  position: absolute;
  z-index: -10;
  transform: translate(-50%, -50%);
}
.francuaImg{
  top: -7%;
  left: 67%;
}
.usaImg{
  top: 22%;
  left: 100%;
}
.canadaImg{
  top: 103%;
  left: 25%;
}
.img7{
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: -1;
  top: 13%;
  left: 25%;
  width: 10vw;
}
.img10{
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: -1;
  top: 5vw;
  left: 70%;
  width: 22vw;
}
@media screen and (max-width: 1200px) {
  .countryImg{
    z-index: 0;
  }
}