.wrapper {
  margin-top: 35px;
}
.logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
  transform: translateX(100%);
  transition: all 1s ease-in-out;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.1vw;
  width: 11.1vw;
  border-radius: 50%;
  border: 1px solid #ced0d6;
  @media screen and (max-width: 1200px) {
    width: 120px;
    height: 120px;
  }
}
.logo img {
  width: 6vw;
  @media screen and (max-width: 1200px) {
    width: 60px;
  }
}

.onLoad {
  transform: translateX(0);
}

@media screen and (max-width: 800px) {
  .logo {
    border: 0;
    width: calc(50% - 10px);
    height: 75px;
  }
  .logo img {
    width: 80px;
  }
}
