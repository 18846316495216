.card {
  max-width: 440px;
  height: 100%;
  flex: 0 0 440px;
  border-radius: 30px;
  background: var(--accent);
  padding: 92px 32px 68px 78px;
  background: var(--accent) url(/src/assets/images/glassy-4.png) no-repeat 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card.even {
  background: var(--accent) url(/src/assets/images/glassy-4-green.png) no-repeat 100%;
  background-size: cover;
}

.suptitle {
  color: white;
  font-family: Neue Machina;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
}

.title {
  font-family: Neue Machina;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.64px;
  margin-bottom: 50px;
}

.description {
  color: var(--dark);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 30px;
}

.footer {
  margin-top: 110px;
  color: #fff;
  text-align: right;
  font-family: Neue Machina;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-align: left;
}
.footerContent {
  font-size: 80px;
  line-height: 80px;
}
.footerContentBlack {
  color: black;
}

@media screen and (max-width: 1650px) {
  .footerContent {
    font-size: 50px;
  }

  .footer span {
    font-size: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .card {
    display: none;
  }
  .card:nth-child(1) {
    display: flex;
    flex: auto;
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  .card {
    display: none;
    padding: 40px;
    height: 591px;
  }
}
