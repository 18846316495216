.form-title {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
}

.form-desc {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.36px;
}

.form-link {
  color: var(--accent, #4FA778);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.14px;
}

@media screen and (max-width: 1000px) {
  .form-title { font-size: 24px; }
  .form-desc { font-size: 16px; }
  .form-link { font-size: 12px; }
}
