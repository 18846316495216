.wrapper{
  display: flex;
  flex-direction: row;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  border-radius: 17px;
  padding: 55px 40px 55px 40px;
  gap: 11.31px;
  color: white;
  width: 40%;
  z-index: 1;
}
.number{
  font-size: 3.1vw;
  line-height: 3.1vw;
  font-weight: 700;;
  margin: 0;
  margin-left: -20px;
  margin-bottom: auto;
  font-family: Neue Machina;
  font-weight: 500;
}
@media screen and (max-width: 1006.29px){
  .number{
    font-size: 32px;
    line-height: 32px;  
  }
}
.right{
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  margin-left: auto;
}
.title{
  font-size: 2.19vw;
  line-height: 2.19vw;
  font-weight: 500;
  margin: 0;
  font-family: Neue Machina;
  font-weight: 500;
  margin-bottom: 5.21vw;
  text-transform: uppercase;
}
@media screen and (max-width: 1095.89px){
  .title{
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 479.85px){
  .title{
    margin-bottom: 25px;
  }
}
.description{
  font-size: 16px;
  line-height: 16px;
  margin: auto 0 0 0;
  color: black;
  font-family: Manrope;
  font-weight: 500;
}
.img{
  position: absolute;
  left: 25px;
  bottom: 70px;
  background: none;
  outline: none;
  border: none;
}
@media screen and (max-width: 1200px) {
  .wrapper{
    flex-direction: column;
    width: 100%;
    min-width: 283px;
    height: fit-content;
    padding-bottom: 100px;
  }
  .right{
    width: 100%;
  }
  .title{
    width: 25%;
    margin-top: 30px;
  }
  .number{
    margin-left: 0;
  }
  .wrapper{
    flex-direction: column;
  }
  .img{
    bottom: 25px;
  }
}
@media screen and (max-width: 800px){
  .right{
    margin-left: 0;
  }
  .title{
    margin-top: 20px;
  }
}
