.cardsWrapper {
  display: flex;
  gap: 20px;
}

.card {
  padding: 55px;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--white, #FFF);

  @media screen and (max-width: 600px) {
    padding: 35px 30px;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.title {
  color: var(--accent, #4FA778);
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1.6px;
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 18px;
  }
}

.suptitle {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: 5px;
}

.langSelector {
  border-radius: 10px;
  border: 1px solid var(--grey-3);
  padding: 20px 25px;
  background: var(--grey-4, #F4F5F7);
}

@media screen and (max-width: 1600px) {
  .langSelector {
    background: #F4F5F7;
  }
}
@media screen and (max-width: 600px) {
  .langSelector {
    padding: 20px;
  }
}

.image {
  max-width: 530px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--grey-3, #EBEFF4);
  margin-bottom: 20px;
}

.actions button {
  width: 100%;
}

@media screen and (max-width: 1140px) {
  .cardsWrapper {
    flex-direction: column;
  }
  
  .image {
    max-width: 100%;
  }
}
