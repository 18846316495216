.sidebar {
  padding: 73px 55px 55px 55px;
  background: #F9F9F9;
  border-radius: 30px;
  align-self: flex-start;
  transition: 500ms ease-in-out;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 75px;
  gap: 16px;
  justify-content: space-between;
}

.header img {
  cursor: pointer;
}

.logoDesktop {
  display: block;
}

.logoMobile {
  display: none;
  width: 32px;
}

.toggleButton {
  display: none;
}
@media screen and (max-width: 1543px) {
  .toggleButton {
    display: block;
  }
}

.profile {
  display: flex;
  align-items: center;
  gap: 25px;
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 35px;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.userRole {
  display: flex;
  align-items: center;
  gap: 8px;
}

.menu {
  margin-bottom: 100px;
}

@media screen and (max-width: 1543px) {
  .sidebar {
    position: fixed;
    top: 35px;
    bottom: 0;
    left: 35px;
    z-index: 999;
    transform: translateX(calc(-100% - 35px));
    background: white;
  }

  .inner {
    overflow-y: auto;
    height: 100%;
  }

  .sidebarOpen {
    transform: translateX(0);
  }
}

@media screen and (max-width: 805px) {
  .header {
    display: none;
  }

  .sidebar {
    position: fixed;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    background: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    top: auto;
    left: 0;
  }

  .menu {
    margin: 0;
  }
}
