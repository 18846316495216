.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  gap: 8px;
}

.container {
  position: relative;
  display: inline-block;
}

.checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.border {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}

.mark {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);
  transition: 0.1s ease-in-out;
  z-index: 100;
}

.disabled {
  border: 1px solid var(--color-grey1);
}

.disabled .mark {
  background: var(--color-grey1);
}

.checkbox input:checked + .mark {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
