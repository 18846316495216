.wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.card{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 0 auto;
  padding: 55px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
}
.card p{
  margin: 0;
}
.card > .content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card > .icon{
  padding: 20px;
  background-color: #F4F5F7;
  border-radius: 10px;
}
.card > .content > p{
  color: #8F939C;
}

.card .value{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
  gap: 10px;
}
.card .value img{
  height: 35px;
  width: 35px;
}

.cannaHoldCards{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.expired {
  position: relative;
}

.overlay {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  background: white;
  border-radius: 15px;
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 555;
}

.overlayText {
  color: var(--dark);
  font-weight: 800;
  font-size: 20px;
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 555;
}

.cannaHoldCard{
  /* background-color: #F4F5F7; */
  padding-bottom: 20px;
}
.cannaHoldCard p{
  margin: 0;
}
.cannaHoldCard .top{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #6F7DD7; */
  color: white;
  padding: 20px;
  border-radius: 15px 15px 0 0;
}
.cannaHoldCard .top .title{
  font-size: 20px;
  font-weight: 600;
}
.cannaHoldCard .top .text{
  font-size: 14px;
  font-weight: 300;
}
.cannaHoldCard .content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
}
.cannaHoldCard .content .name{
  color: #8F939C;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}
.cannaHoldCard .content .value{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
}
.cannaHoldCard .content .value img{
  height: 18px;
}
.cannaHoldCard .content .logo{
  margin: 20px 0;
}
.cannaHoldCard .content .logo img{
  height: 31px;
}


@media screen and (max-width: 500px) {
  .cards{
    gap: 10px;
  }
  .card{
    padding: 35px 30px;
  }
  .card .value{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 26px;
    line-height: 26px;
    font-weight: 600;
    gap: 10px;
  }
  .card > .icon{
    padding: 10px;
    background-color: #F4F5F7;
  }
  .cannaHoldCard{
    background-color: #F4F5F7;
    width: 100%;
  }

  .cannaHoldCards{
    flex-direction: column;
  }
}
