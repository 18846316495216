.wrapper{
  display: flex;
  flex-direction: column;
  width: 585px;
  height: 100%;
}

.title, 
.subtitle, 
.addition, 
.text{
  margin: 0;
}

.header{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header > .title{
  color: var(--dark, #001007);
  font-family: Neue Machina;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 39.9px */
  letter-spacing: -2.52px;
}
.top{
  display: flex;
  flex-direction: row;
  gap: 45px;
}
.subtitle{
  color: var(--accent, #4FA778);
  font-family: Neue Machina;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 22px */
}
.addition{
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}
.line{
  width: 100%;
  height: 0;
  border-top: 1px solid #DFDFDF;
  margin: 25px 0;
}
.content{
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: calc(100% - 260px);
  overflow-y: scroll;
  padding-right: 10px;
}
.cards:nth-child(1){
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
}
.cards:nth-child(2){
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.cards:nth-child(1) .card{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.cards:nth-child(2) .card{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cards:nth-child(1) .card .title{
  color: var(--dark, #001007);
  font-family: Neue Machina;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}
.cards:nth-child(2) .card .title{
  color: #838587;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.cards:nth-child(1) .card .text{
  color: #8F939C;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}
.cards:nth-child(2) .card .text{
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.circle{
  position: absolute;
  height: 342px;
  width: 342px;
  background: #67B793;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  top: 65%;
  left: 85%;
}
.circle img{
  position: absolute;
}

@media screen and (max-width: 1200px) {
  .wrapper{
    width: 275px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .top{
    flex-direction: column;
    gap: 15px;
  }
  .header > .title{
    color: var(--dark, #010D00);
    font-family: Neue Machina;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 95%; /* 26.6px */
    letter-spacing: -1.68px;
  }
  .content{
    width: 100%;
    overflow-y: unset;
  }
  .cards:nth-child(1){
    width: 100%;
    gap: 10px;
  }
  .line{
    margin: 15px 0;
  }
  .circle{
    position: relative;
    transform: translate(0,0);
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 260px;
    height: 260px;
  }
}
