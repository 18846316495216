.svgPiWrapper {
  position: relative;
}

.svgPiLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svgPiLabel__loading {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.svgPiLabel__progress {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.54px;
}
