.dropdownWrapper {
  position: relative;
  display: inline-block;
  user-select: none;
}

.selectedOption {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.blur {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
  color: white;
}

.light {
  background: white;
}

.optionImage {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.optionsList {
  list-style-type: none;
  position: absolute;
  margin: 0;
  z-index: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 20px 35px;
  top: 61px;
  left: 50%;
  transform: translate(-50%, 0);
}
.optionList.blur {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
  color: white;
}
.optionsList.light {
  background: white;
  color: black;
}

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.option + .option {
  margin-top: 5px;
}

.option:hover {
}

.dropdownBlock{
  position: absolute;
  height: 110px;
  width: 125px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}