.dashboardCareerPromo {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 900px) {
  .dashboardCareerPromo {
    flex-direction: column;
  }
}

.dashboardCareerPromo div {
  flex: 1 0 50%;
}

.card {
  padding: 35px 55px 55px 55px;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.head button {
  padding: 20px;
}

.career .suptitle {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: 5px;
}

.career .head .title {
  color: #FF9319;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1.6px;
}

.promo {
  display: flex;
  flex-direction: column;
}

.promo .head .suptitle {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 18.785px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.376px;
}

.promo .head .title {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -1.05px;
}

.promo .head {
  margin-bottom: 50px;
}

.promo .card {
  border: 1px solid var(--grey-3, #EBEFF4);
  background: rgba(222, 236, 228, 0.33);
}

.promo .promoBtn {
  background: white;
  border: none;
}

.promo .text {
  padding-top: 50px;
  padding-bottom: 30px;
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.16px;
}

.promo .progress {
  margin-bottom: 50px;
}

.secondaryHead .title {
  color: #8195DB;
  font-family: Manrope;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -1.3px;
}

.secondaryHead {
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid #EBEFF4;
}

.conditions {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.conditionsTitle {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.96px;
  margin-bottom: 25px;
}

.radialProgess {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expired {
  opacity: 0.6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 999;
}
