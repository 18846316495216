.titleWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  margin-bottom: 145px;
  letter-spacing: -3px;
  z-index: 1;
  width: 100%;
}
.title {
  font-size: 9.48vw;
  line-height: 7.48vw;
  text-transform: uppercase;
  margin: 0;
  font-family: Neue Machina;
  font-weight: 400;
}
.title:nth-child(1) {
}
.title:nth-child(2) {
  margin-left: 12.5vw;
}
.img {
  position: absolute;
  max-height: 90%;
  max-width: 90%;
  z-index: 0;
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-bottom: 90px;
}
@media screen and (max-width: 379.75px) {
  .title {
    font-size: 36px;
    line-height: 36px;
  }
}
@media screen and (max-width: 1700px) {
  .titleWrapper {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .cards {
    flex-direction: column;
    align-items: center;
  }
  .titleWrapper {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 800px) {
  .titleWrapper {
    margin: 50px 0;
  }
}
