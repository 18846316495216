.iconButton {
  padding: 20px;
}

.commonBlock {
  padding: 55px;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.box, .bottomBox {
  display: flex;
  align-items: center;
  gap: 35px;
}

.box .label, .bottomBox .label  {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: 5px;
}

.box .value, .bottomBox .value {
  color: var(--accent, #4FA778);
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1.6px;
}

.gridBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.gridItem, .bottomItem {
  display: flex;
  align-items: center;
  padding: 55px;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: var(--white, #FFF);
}

.gridItem .value, .bottomBox .value {
  color: var(--dark);
  display: flex;
  align-items: center;
  gap: 10px;
}

.gridItem img, .bottomBlock img {
  width: 32.5px;
  height: 32.5px;
}

.bottomBlock {
  display: flex;
  gap: 20px;
}

.bottomItem {
  flex: 1 0 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.bottomBox {
}

@media screen and (max-width: 1300px) {
  .commonBlock {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .gridBlock{
    grid-template-columns: 1fr;
    gap:10px
  }
  .bottomBlock{
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .commonBlock .value {
    font-size: 24px;
  }
  .box .value, .bottomBox .value{
    font-size: 24px;
  }
  .commonBlock .label {
    font-size: 14px;
  }
  .box .label, .bottomBox .label{
    font-size: 14px;
  }
  .commonBlock{
    padding: 35px 30px;
  }
  .gridItem{
    padding: 35px 30px;
  }
  .bottomItem{
    padding: 35px 30px;
  }
}