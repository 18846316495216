.card{
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.31);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  border-radius: 17px;
  gap: 11.31px;

  padding: 2.86vw 1.8vw 2.86vw 3.65vw;
  
  @media screen and (max-width: 699.30px){
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 547.95px){
    padding-left: 20px;
    padding-right: 20px
  }
}
.right{
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  margin-left: 50px;
}
.title{
  font-size: 1.67vw;  
  line-height: 1.67vw;
  font-weight: 700;
  font-family: 'Neue Machina';
  margin: 0;
  
  @media screen and (max-width: 1077.84px){
    font-size: 18px;
    line-height: 18px;  
  }
}
.description{
  font-size: 3.39vw;  
  line-height: 3.39vw;
  font-weight: 700;
  width: 200%;
  font-family: Neue Machina;
  margin: auto 0 0 0;
  margin-top: -5px;

  
  @media screen and (max-width: 707.96px){
    font-size: 24px;
    line-height: 24px;  
  }
}