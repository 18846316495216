.header {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 57px;
  right: 62px;
  top: 20px;
  height: 102px;
  border-radius: 15px;
  z-index: 99;
  transition: 0.5s ease-in-out;
}

.hidden {
  top: -122px;
}

.background {
  position: absolute;
  border-radius: inherit;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.background.blur {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);
}
.background.light {
  background: white;
}
.blur {
  color: white;
}

.light {
  background: white;
}

.light a {
  color: var(--dark);
}

.left,
.nav,
.buttons {
  display: flex;
  align-items: center;
}

.left {
  gap: 76px;
}

.nav {
  display: flex;
  gap: 40px;
  margin-left: -6vw;
}

.menuButton {
  display: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.logoContainer {
  cursor: pointer;
}

.mobileLogoContainer {
  display: none;
}
@media screen and (max-width: 800px) {
  .logoContainer {
    display: none;
  }
}
.header button {
  min-width: 120px;
}

@media screen and (max-width: 1200px) {
  .header {
    padding: 20px 10px 20px 20px;
    height: 72px;
    margin-top: 40px;
  }
  .nav,
  .languageSelector {
    display: none !important;
  }
  .logoImg {
    position: absolute;
    left: 50%;
    top: -40px;
    transform: translateX(-50%);
  }

  .menuButton {
    display: block;
  }
  .header button {
    min-width: 0;
  }
  .left {
    gap: 0;
  }
}
@media screen and (max-width: 600px) {
  .header {
    left: 10px;
    right: 10px;
  }
}

@media screen and (max-width: 800px) {
  .header {
    margin-top: 0;
  }
}
