.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  margin-bottom: 70px;
}

.title {
  margin-bottom: 30px;
}

.passwordBlock {
  margin: 0 0 20px 0;
}

.forgot {
  text-align: right;
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 10px;
}

.forgotLink {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.registerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  font-size: 14px;
}

.registerBlock .left {
  display: flex;
  gap: 15px;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .registerBlock {
    font-size: 12px;
    flex-direction: column;
    gap: 6.5px;
  }
}
