.dashboardCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 35px 55px 55px 55px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid var(--grey-3, #EBEFF4);
  background: #FFF;
  flex: 1 0 auto;
  max-width: 420px;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}

.head span {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.44px;
}

.head button {
  padding: 20px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 30px;
  flex: 1 0 auto;
}

.field {}

.key {
  color: var(--grey-1, #8F939C);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.16px; 
  margin-bottom: 5px;
}

.valueWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.valueWrapper img {
  width: 27px;
  height: 27px;
}

.value {
  color: var(--dark, #001007);
  font-family: Manrope;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -1.3px;
}

.bigValue {
  font-size: 32px;
  padding-bottom: 15px;
}

.footer {
  width: 100%
}

.footer button {
  width: 100%;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapper svg {
  width: 27px;
}

.selectable {
  cursor: pointer
}

.selected svg {
  filter: invert(55%) sepia(19%) saturate(932%) hue-rotate(95deg) brightness(101%) contrast(91%);
}

.rest {
  filter: invert(91%) sepia(6%) saturate(130%) hue-rotate(182deg) brightness(95%) contrast(84%);
}

@media screen and (max-width: 1600px) {
  .value {
    font-size: 20px;
  }

  .bigValue {
    font-size: 22px;
  }
  
  .head button {
    padding: 15px;
  }
}

@media screen and (max-width: 805px) {
  .dashboardCards {
    gap: 10px;
  }
}


@media screen and (max-width: 500px) {
  .card{
    max-width: none;
    min-width: 0px;
    width: 100%;
    padding: 35px 30px;
  }
}
